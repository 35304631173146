import { useGameContext } from "../../../pages/session_game/context/context.game";
import PasswordModal from "../modalTemplates/PasswordModal";
import { ReactElement, useEffect, useMemo, useState } from "react";
import ClickableObject, { ClickableObjectParameters } from "./components/ClickableObject";
import StrTemp from "../../../shared/services/formatter/stringTemplates";
import ImageRatioBackground from "../../../shared/components/image/ImageRatioBackground";
import Normalize from "../../../shared/services/formatter/normalize";
import { ModalParameters } from "../../../shared/models/Templates";
import BasicModalTemplate from "../modalTemplates/BasicModalTemplate";
import useImagePreloader from "./callbacks/useImagePreloader";

export default function BasicStepTemplate(
    props: {
        openPasswordModal?: boolean;
        setOpenPasswordModal?: (open: boolean) => void;
        children?: ReactElement | ReactElement[];
    }
) {
    const context = useGameContext();
    const { imagesPreloaded } = useImagePreloader(context.step?.modals ? Object.values(context.step.modals) : []);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);
    const [modalsOpened, setModalsOpened] = useState<string[]>([]);
    const bgImage = useMemo(() => (context.step?.room?.backgroundImage && context.player?.number) ? StrTemp.playerNumber(context.step.room.backgroundImage, context.player.number) : "", [context.step?.room?.backgroundImage, context.player?.number]);
    const filteredModalKeys: {[key: string]: ModalParameters} = useMemo(
        () => Object.fromEntries(
            (context.step?.modals && Object
                .entries(context.step.modals)
                .filter(([_, modal]) => (
                    (!modal.players || context.player?.numberAmoung(modal.players)) && context.player?.hasAdaptation(modal.adaptation)
                ))
                .map(([key, value]) => [Normalize.modalKey(key), value])
            ) ?? []
        ), [context.step?.modals, context.player, context.player?.number, context.player?.adaptation])

    const setOpenPass = (open: boolean) => {
        setOpenPasswordModal(open);
        props.setOpenPasswordModal && props.setOpenPasswordModal(open);
    }

    const openModal = (label: string) => {
        if (!filteredModalKeys[label]) return;
        setModalsOpened([...modalsOpened, label]);
    }

    const closeModal = (label: string) => {
        setModalsOpened(modalsOpened.filter(label => label !== label));
    }

    useEffect(() => {
        setModalsOpened([]);
    }, [filteredModalKeys]);

    const content = useMemo(() => {
        if (props.children) {
            return props.children;
        }

        const timeDateWhenVisible = context?.team?.stepStartDate && context?.step?.room?.timeBeforeVisible ? context.team.stepStartDate.getTime() + context.step.room.timeBeforeVisible * 1000 : undefined
        
        return context.step?.room?.buttons.filter(button => !button.players || context.player?.numberAmoung(button.players)).map(button => {
            return <ClickableObject button={button} setOpenPasswordModal={setOpenPasswordModal} openModal={openModal} timeWhenVisible={timeDateWhenVisible} />
        });
    }, [props.children, context.step, context.player?.number, context?.team?.stepStartDate, context?.step?.room?.timeBeforeVisible]);
        


    return (
        <ImageRatioBackground bgImage={bgImage}>
            <PasswordModal open={openPasswordModal || props.openPasswordModal === true} setOpen={setOpenPass} />
            {filteredModalKeys && Object.keys(filteredModalKeys).map(key => {
                return <BasicModalTemplate open={modalsOpened.includes(key)} setOpen={(open) => open ? openModal(key) : closeModal(key)} modal={filteredModalKeys[key]} />
            })}
            {content}
        </ImageRatioBackground>
    );
}

export type StepTemplateParameters = {
    backgroundImage?: string;
    buttons: ClickableObjectParameters[];
    templateOptions?: {[key: string]: string};
    timeBeforeVisible?: number; // in seconds
};