import { Button, Flex, Form, FormInstance } from "antd";
import SyncronizedInput from "../../../shared/components/input/SyncronizedInput";
import { useGameContext } from "../../../pages/session_game/context/context.game";
import t from "../../../configs/i18n";
import CanClosePopup from "../../../shared/components/text/CanClosePopup";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import PopupGoodAnswer from "../../../shared/components/popup/PopupGoodAnswer";
import Answer from "../../../shared/services/answer/Answer";
import Notification from "../../../shared/services/notification/Notification";
import Normalize from "../../../shared/services/formatter/normalize";
import { ModalParameters, PasswordInput, SimpleInput } from "../../../shared/models/Templates";
import BasicModalTemplate from "./BasicModalTemplate";
import StyleService from "../../../shared/services/templates/StyleService";
import MouseFloatingText from "../../../shared/components/mouse/MouseFloatingText";
import FitText from "../../../shared/components/text/FitText";
import SyncronizedSelect from "../../../shared/components/input/SyncronizedSelect";
import "../styles/inputs.css";
import AppSpinner from "../../../shared/components/spinner/AppSpinner";
import { shuffleOptions } from "../../../shared/services/mapper/Shuffle";

export default function PasswordModal(
    props: {
        open: boolean;
        setOpen?: (open: boolean) => void;
    }
) {
    const [form] = Form.useForm();
    const context = useGameContext();
    const [openSuccess, setOpenSuccess] = useState<boolean>(false);
        
    const popup = <PopupGoodAnswer currentUser={context.user} open={openSuccess} setOpen={setOpenSuccess} team={context.team} player={context.player} playersTeam={context.playersTeam}/>
    const answers = useMemo(() => context.step?.passwordModal?.inputs.map((input: SimpleInput) => input.answer).filter((answer: string) => answer !== undefined) as string[], [context.step?.passwordModal?.inputs]);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [onHoverValidate, setOnHoverValidate] = useState<boolean>(false);
    const [noRender, setNoRender] = useState<boolean | undefined>(undefined);
    const componentRef = useRef<HTMLDivElement>(null);

    const updateButton = (value: string, id: string) => {
        setNoRender(!!context.step?.passwordModal?.content);
        // Check if all fields have values
        const allFieldsFilled = Object.values(form.getFieldsValue() as string[]).every(
            (value) => value !== undefined && value !== ""
        );
        setIsButtonDisabled(!allFieldsFilled); // Disable if not all fields are filled
    };

    const validate = () => {
        const inputs = form.getFieldsValue();
        if (!Answer.isCorrectWithOrder(inputs, answers, false)) {
            Notification.error(t.sessionGame.wrongAnswer);
            return;
        }
        props.setOpen && props.open && props.setOpen(false);
        setOpenSuccess(true);
    }

    useEffect(() => {
        if (context.solution?.solutionOn) {
            props.setOpen && props.setOpen(true);
        }
    }, [context.solution?.solutionOn])

    useEffect(() => {
        setNoRender(!!context.step?.passwordModal?.content);
        if (context.step && props.open && !context.step.passwordModal) {
            props.setOpen && props.setOpen(false);
            setOpenSuccess(true);
            return;
        }
    }, [context.step, props.open]);

    const onMouseEnter = () => {
        setOnHoverValidate(true);
    }

    const onMouseLeave = () => {
        setOnHoverValidate(false);
    }
    
    if (!context.step?.passwordModal) return popup;

    if (noRender === undefined) {
        return <AppSpinner size={"large"}/>;
    }

    return (
        <>
            {popup}
            <BasicModalTemplate
                {...props}
                modal={context.step?.passwordModal as ModalParameters}
                title={<FitText className="w-full h-[2.5vmin] font-normal italic text-center m-0">{t.sessionGame.canClosePopup}</FitText>}
                footer={!(noRender && !context.step?.passwordModal?.forceValidateButton) &&
                    <Form.Item><Flex ref={componentRef} className="w-full translate-y-[50%] justify-center mt-[3%] h-full"><Flex className="w-[20vmin] h-[5vmin] items-center justify-center" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                        <Button
                            type="primary"
                            onClick={validate}
                            className="font-bold w-fit h-full px-[2.5vmin]"
                            disabled={isButtonDisabled}
                        >
                            <FitText onlyHeight className="w-full h-full text-center flex items-center justify-center">{context.step?.passwordModal?.validateButton ?? t.common.validate.toUpperCase()}</FitText>
                        </Button>
                        <MouseFloatingText text={t.common.allFieldsFilled} condition={isButtonDisabled && onHoverValidate} start={componentRef.current?.getBoundingClientRect()}/>
                    </Flex></Flex></Form.Item>
                }
                unclosable={context.solution?.solutionOn}
                noRender={noRender}
            >
                <PasswordModalContent form={form} onChange={updateButton}/>
            </BasicModalTemplate>
        </>
    );
}

function PasswordModalContent(
    props: {
        form: FormInstance;
        onChange: (value: string, id: string) => void;
    }
): ReactElement {
    const context = useGameContext();
    const { form } = props;
    const inputs = useMemo(() => context.step?.passwordModal?.inputs.map(input => ({...context.step?.passwordModal?.defaultInput, ...input})), [context.step?.passwordModal?.inputs, context.step?.passwordModal?.defaultInput]);


    useEffect(() => {
        if (context.solution?.solutionOn && inputs) {
            const correctAnswers = inputs.map(input => input.answer);
            const sortedKeys = Object.keys(form.getFieldsValue()).sort();
            const sortedValues = sortedKeys.map((_, i) => correctAnswers[i]);
            const formValues = Object.fromEntries(sortedKeys.map((key, index) => [key, sortedValues[index]]));
            form.setFieldsValue(formValues);
        }
    }, [context.solution?.solutionOn]);

    if (context.step?.passwordModal?.content && form) {
        return <>{context.step?.passwordModal?.content(form, props.onChange)}</>;
    }
    return (
        <>
            {inputs?.map(input => {
                switch (input.type) {
                    case "select": return <SelectSyncronizedInput input={input} form={form} onChange={props.onChange}/>;
                    default: return <SimpleSyncronizedInput input={input} form={form} onChange={props.onChange}/>;
                }
            })}
        </>
    );
}

function SimpleSyncronizedInput({input, form, onChange}: {input: PasswordInput, form: FormInstance, onChange: (value: string, id: string) => void}) {
    const context = useGameContext();
    const watchedValues = Form.useWatch((values) => values, form);
    const getClassName = (input: SimpleInput) => StyleService.getInputColorClassName(input, watchedValues && Normalize.answer(watchedValues[`${context.team?.id}-${context.team?.step}-${input.label}`]));

    return (
        <Flex className={"absolute "} style={{left: `${input.position?.x}%`, top: `${input.position?.y}%`, width: `${input.size}%`, height: 'fit-content', transform: StyleService.getTranslate(input.position?.type)}}>
            <SyncronizedInput
                className={`${getClassName(input)} ${input.centered ? "text-center" : ""}`}
                id={`${context.team?.id}-${context.team?.step}-${input.label}`}
                player={context.player}
                playersTeam={context.playersTeam}
                number={input.player && !Array.isArray(input.player) ? input.player : undefined}
                playersNumbers={input.player && Array.isArray(input.player) ? input.player : undefined}
                form={form}
                color={input.color}
                placeHolder={input.placeHolder}
                onChange={onChange}
                isDisabled={input.auto_check && watchedValues && Normalize.answer(watchedValues[`${context.team?.id}-${context.team?.step}-${input.label}`]) === input.answer}
                customSize={input.height ?? 2.5}
                textSize={input.textSize ?? 1}
            />
        </Flex>
    );
}

function SelectSyncronizedInput({input, form, onChange}: {input: PasswordInput, form: FormInstance, onChange: (value: string, id: string) => void}) {
    const context = useGameContext();

    return <Flex className={"absolute reponsive-select"} style={{left: `${input.position?.x}%`, top: `${input.position?.y}%`, width: `${input.size}%`, height: 'fit-content', transform: StyleService.getTranslate(input.position?.type)}}>
        <SyncronizedSelect
            id={`${context.team?.id}-${context.team?.step}-${input.label}`}
            player={context.player}
            playersTeam={context.playersTeam}
            options={shuffleOptions(input.options, answer => answer)?.map(answer => ({label: answer, value: answer})) ?? []}
            form={form}
            width={"100%"}
            placeHolder={input.placeHolder}
            number={input.player && !Array.isArray(input.player) ? input.player : undefined}
            playersNumbers={input.player && Array.isArray(input.player) ? input.player : undefined}
            onChange={onChange}
            popupMatchSelectWidth={window.innerWidth / 2.2}
            height={`${input.height ?? 2.5}em`}
            textSize={input.textSize ?? 1}
            noForm
        />
    </Flex>
}
