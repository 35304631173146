import { Scenario } from "../scenarios";
import MissingPlayers11 from "./dgse/modalContent/MissingPlayers11";
import PasswordStep1 from "./dgse/modalContent/PasswordStep1";
import Stage9password from "./dgse/modalContent/Stage9password";
import Step12 from "./dgse/steps/Step12";
import MissingHintTemplate from "./modalTemplates/MissingHintTemplate";
import BasicStepTemplate from "./stepTemplates/BasicStepTemplate";
import VideoWithInformation from "./stepTemplates/VideoWithInformation";

const dgseFrScenario: Scenario = {
    title: 'DGSE',
    description: '',
    longDescription: '',
    notLearningType: true,
    lang: "fr",
    rules: "",
    tablet: false,
    newFormat: true,
    steps: [
        {
            component: <BasicStepTemplate/>,
            baseHints: [],
            passwordModal: {
                size: {w: 110, h: 25},
                inputs: [
                    {
                        label: "answer",
                        answer: "1",
                        auto_check: true,
                        player: 1
                    },
                    {
                        label: "answer2",
                        answer: "3",
                        auto_check: true,
                        player: 2
                    },
                    {
                        label: "answer3",
                        answer: "2",
                        auto_check: true,
                        player: 3
                    },
                    {
                        label: "answer4",
                        answer: "1",
                        auto_check: true,
                        player: 4
                    }
                ],
                descriptions: [
                    {
                        size: 50,
                        type: "text",
                        content: "Déverrouillage du code",
                        position: {x: 0, y: 0},
                        unrendered: true
                    }
                ],
                content: (form, onChange) => <PasswordStep1 form={form} onChange={onChange} />,
                forceValidateButton: true
            },
            modals: {
                "vocal": {
                    size: {w: 50, h: 14},
                    descriptions: [
                        {
                            type: "text",
                            content: "Vous avez un nouveau message",
                            position: {x: 50, y: 20, type: "center"},
                            size: 1.2,
                        },
                        {
                            type: "audio",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step1/audio.mp3",
                            position: {x: 50, y: 65, type: "bottomCenter"},
                            size: 100,
                        }
                    ]
                }
            },
            room: {
                backgroundImage: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step1/background.webp",
                buttons: [
                    {
                        type: "invisible",
                        size: {w: 11, h: 15},
                        action: {
                            type: "modal",
                            target: "vocal",
                        },
                        position: {x: 52.2, y: 69}
                    },
                    {
                        type: "invisible",
                        size: {w: 14.1, h: 15},
                        action: {
                            type: "password_modal",
                        },
                        position: {x: 27, y: 55.45}
                    }
                ],
                timeBeforeVisible: 180
            }
        },
        {
            component: <VideoWithInformation/>,
            baseHints: [],
            passwordModal: {
                size: {w: 110, h: 25},
                inputs: [
                    {
                        label: "answer",
                        answer: "2",
                        auto_check: true,
                        player: 1
                    },
                    {
                        label: "answer2",
                        answer: "0",
                        auto_check: true,
                        player: 2
                    },
                    {
                        label: "answer3",
                        answer: "2",
                        auto_check: true,
                        player: 3
                    },
                    {
                        label: "answer4",
                        answer: "1",
                        auto_check: true,
                        player: 4
                    }
                ],
                descriptions: [
                    {
                        size: 50,
                        type: "text",
                        content: "Déverrouillage du code",
                        position: {x: 0, y: 0},
                        unrendered: true
                    }
                ],
                content: (form, onChange) => <PasswordStep1 form={form} onChange={onChange} />,
                forceValidateButton: true
            },
            room: {
                buttons: [],
                templateOptions: {
                    "video": "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step2/video.webm",
                    "hint": "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step2/hint_p{player_number}.webp",
                    "button": "Accès à la salle de surveillance satellite"
                }
            }
        },
        {
            component: <BasicStepTemplate/>,
            baseHints: [MissingHintTemplate],
            passwordModal: {
                size: {w: 150, h: 30},
                inputs: [
                    {
                        label: "answer",
                        answer: "N",
                        auto_check: true,
                        color: "#ff0000", //  bg-[#ff0000] disabled:bg-[#ff0000] focus:bg-[#ff0000] hover:bg-[#ff0000]
                        correctTextColor: "#ffffff", // text-[#ffffff] disabled:text-[#ffffff]
                        player: [1]
                    },
                    {
                        label: "answer2",
                        answer: "I",
                        auto_check: true,
                        color: "#0070c0", //  bg-[#0070c0] disabled:bg-[#0070c0] focus:bg-[#0070c0] hover:bg-[#0070c0]
                        correctTextColor: "#ffffff", // text-[#ffffff] disabled:text-[#ffffff]
                        player: [2]
                    },
                    {
                        label: "answer3",
                        answer: "G",
                        auto_check: true,
                        color: "#ffc000", //  bg-[#ffc000] disabled:bg-[#ffc000] focus:bg-[#ffc000] hover:bg-[#ffc000]
                        correctTextColor: "#ffffff", // text-[#ffffff] disabled:text-[#ffffff]
                        player: [3]
                    },
                    {
                        label: "answer4",
                        answer: "E",
                        auto_check: true,
                        color: "#00b050", //  bg-[#00b050] disabled:bg-[#00b050] focus:bg-[#00b050] hover:bg-[#00b050]
                        correctTextColor: "#ffffff", // text-[#ffffff] disabled:text-[#ffffff]
                        player: [4]
                    },
                    {
                        label: "answer5",
                        answer: "R",
                        auto_check: true,
                        color: "#9900ff", //  bg-[#9900ff] disabled:bg-[#9900ff] focus:bg-[#9900ff] hover:bg-[#9900ff]
                        correctTextColor: "#ffffff", // text-[#ffffff] disabled:text-[#ffffff]
                        player: [1]
                    }
                ],
                descriptions: [
                    {
                        type: "text",
                        content: "COORDONNÉES GÉOGRAPHIQUES",
                        position: {x: 0, y: 0},
                        unrendered: true,
                        size: 65
                    }
                ],
                content: (form, onChange) => <PasswordStep1 form={form} onChange={onChange} />,
                forceValidateButton: true
            },
            room: {
                backgroundImage: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/background_pair{pair_number}.webp",
                buttons: [
                    {
                        type: "invisible",
                        size: {w: 12, h: 15},
                        action: {
                            type: "modal",
                            target: "grey",
                        },
                        position: {x: 19, y: 3},
                        players: [1, 2],
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 12, h: 15},
                        action: {
                            type: "modal",
                            target: "grey",
                        },
                        position: {x: 32, y: 3},
                        players: [1, 2],
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 12, h: 15},
                        action: {
                            type: "modal",
                            target: "blue",
                        },
                        position: {x: 45, y: 3},
                        players: [1, 2]
                    },
                    {
                        type: "invisible",
                        size: {w: 12, h: 15},
                        action: {
                            type: "modal",
                            target: "grey",
                        },
                        position: {x: 58.1, y: 3},
                        players: [1, 2],
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 12, h: 15},
                        action: {
                            type: "modal",
                            target: "purple",
                        },
                        position: {x: 71.2, y: 3},
                        players: [1, 2]
                    },
                    {
                        type: "invisible",
                        size: {w: 15.3, h: 19.5},
                        action: {
                            type: "modal",
                            target: "red",
                        },
                        position: {x: 15, y: 23.5},
                        players: [1, 2]
                    },
                    {
                        type: "invisible",
                        size: {w: 15.3, h: 19.5},
                        action: {
                            type: "modal",
                            target: "grey",
                        },
                        position: {x: 33.8, y: 23.5},
                        players: [1, 2],
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 15.3, h: 19.5},
                        action: {
                            type: "modal",
                            target: "green",
                        },
                        position: {x: 52.8, y: 23.5},
                        players: [1, 2]
                    },
                    {
                        type: "invisible",
                        size: {w: 15.3, h: 19.5},
                        action: {
                            type: "modal",
                            target: "grey",
                        },
                        position: {x: 71.7, y: 23.5},
                        players: [1, 2],
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 23.5, h: 23},
                        action: {
                            type: "password_modal",
                        },
                        position: {x: 2.2, y: 49},
                        players: [1, 2]
                    },
                    {
                        type: "invisible",
                        size: {w: 23.5, h: 23},
                        action: {
                            type: "modal",
                            target: "grey",
                        },
                        position: {x: 39.25, y: 49},
                        players: [1, 2],
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 23.5, h: 23},
                        action: {
                            type: "modal",
                            target: "yellow",
                        },
                        position: {x: 65.4, y: 49},
                        players: [1, 2]
                    },
                    {
                        type: "invisible",
                        size: {w: 8.2, h: 14.5},
                        action: {
                            type: "modal",
                            target: "buildings",
                        },
                        position: {x: 46, y: 20},
                        players: [3, 4]
                    },
                    {
                        type: "invisible",
                        size: {w: 15.2, h: 19},
                        action: {
                            type: "password_modal",
                        },
                        position: {x: 18.8, y: 50.8},
                        players: [3, 4]
                    },
                    {
                        type: "invisible",
                        size: {w: 20.2, h: 18.8},
                        action: {
                            type: "modal",
                            target: "grey",
                        },
                        position: {x: 38.2, y: 50.8},
                        players: [3, 4],
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 15.3, h: 19.2},
                        action: {
                            type: "modal",
                            target: "grey",
                        },
                        position: {x: 63.3, y: 50.6},
                        players: [3, 4],
                        dummy: true
                    },
                ],
                timeBeforeVisible: 180
            },
            modals: {
                "grey": {
                    size: {w: 100, h: 63},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/grey.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 96,
                        }
                    ],
                },
                "blue": {
                    size: {w: 100, h: 35},
                    descriptions: [
                        {
                            type: "text_card",
                            content: "O.516, _.___",
                            position: {x: 50, y: 50, type: "center"},
                            templateOptions: {
                                color: "#0070c0", // bg-[#0070c0]
                                textColor: "#ffffff", // text-[#ffffff]
                                cardWidth: "96",
                                cardHeight: "94",
                                textSize: "5"
                            }
                        },
                    ],
                    players: [1]
                },
                "blue&": {
                    size: {w: 100, h: 35},
                    descriptions: [
                        {
                            type: "text_card",
                            content: "_.___, E.425",
                            position: {x: 50, y: 50, type: "center"},
                            size: 40,
                            templateOptions: {  
                                color: "#0070c0", // bg-[#0070c0]
                                textColor: "#ffffff", // text-[#ffffff]
                                cardWidth: "96",
                                cardHeight: "94",
                                textSize: "5"
                            }
                        },
                    ],
                    players: [2]
                },
                "purple": {
                    size: {w: 100, h: 35},
                    descriptions: [
                        {
                            type: "text_card",
                            content: "O.923, _.___",
                            position: {x: 50, y: 50, type: "center"},
                            size: 40,
                            templateOptions: {
                                color: "#9900ff", // bg-[#0070c0]
                                textColor: "#ffffff", // text-[#ffffff]
                                cardWidth: "96",
                                cardHeight: "94",
                                textSize: "5"
                            }
                        },
                    ],
                    players: [1]
                },
                "purple&": {
                    size: {w: 100, h: 35},
                    descriptions: [
                        {
                            type: "text_card",
                            content: "_.___, N.425",
                            position: {x: 50, y: 50, type: "center"},
                            size: 40,
                            templateOptions: {
                                color: "#9900ff", // bg-[#0070c0]
                                textColor: "#ffffff", // text-[#ffffff]
                                cardWidth: "96",
                                cardHeight: "94",
                                textSize: "5"
                            }
                        },
                    ],
                    players: [2]
                },
                "yellow": {
                    size: {w: 100, h: 35},
                    descriptions: [
                        {
                            type: "text_card",
                            content: "_.___, O.619",
                            position: {x: 50, y: 50, type: "center"},
                            size: 40,
                            templateOptions: {
                                color: "#ffc000", // bg-[#0070c0]
                                textColor: "#ffffff", // text-[#ffffff]
                                cardWidth: "96",
                                cardHeight: "94",
                                textSize: "5"
                            }
                        },
                    ],
                    players: [1]
                },
                "yellow&": {
                    size: {w: 100, h: 35},
                    descriptions: [
                        {
                            type: "text_card",
                            content: "N.525, _.___",
                            position: {x: 50, y: 50, type: "center"},
                            size: 40,
                            templateOptions: {
                                color: "#ffc000", // bg-[#0070c0]
                                textColor: "#ffffff", // text-[#ffffff]
                                cardWidth: "96",
                                cardHeight: "94",
                                textSize: "5"
                            }
                        },
                    ],
                    players: [2]
                },
                "red": {
                    size: {w: 100, h: 35},
                    descriptions: [
                        {
                            type: "text_card",
                            content: "_.___, E.935",
                            position: {x: 50, y: 50, type: "center"},
                            size: 40,
                            templateOptions: {
                                color: "#ff0000", // bg-[#0070c0]
                                textColor: "#ffffff", // text-[#ffffff]
                                cardWidth: "96",
                                cardHeight: "94",
                                textSize: "5"
                            }
                        },
                    ],
                    players: [1]
                },
                "red&": {
                    size: {w: 100, h: 35},
                    descriptions: [
                        {
                            type: "text_card",
                            content: "N.201, _.___",
                            position: {x: 50, y: 50, type: "center"},
                            size: 40,
                            templateOptions: {
                                color: "#ff0000", // bg-[#0070c0]
                                textColor: "#ffffff", // text-[#ffffff]
                                cardWidth: "96",
                                cardHeight: "94",
                                textSize: "5"
                            }
                        },
                    ],
                    players: [2]
                },
                "green": {
                    size: {w: 100, h: 35},
                    descriptions: [
                        {
                            type: "text_card",
                            content: "O.931, _.___",
                            position: {x: 50, y: 50, type: "center"},
                            size: 40,
                            templateOptions: {
                                color: "#00b050", // bg-[#0070c0]
                                textColor: "#ffffff", // text-[#ffffff]
                                cardWidth: "96",
                                cardHeight: "94",
                                textSize: "5"
                            }
                        },
                    ],
                    players: [1]
                },
                "green&": {
                    size: {w: 100, h: 35},
                    descriptions: [
                        {
                            type: "text_card",
                            content: "_.___, N.623",
                            position: {x: 50, y: 50, type: "center"},
                            size: 40,
                            templateOptions: {
                                color: "#00b050", // bg-[#0070c0]
                                textColor: "#ffffff", // text-[#ffffff]
                                cardWidth: "96",
                                cardHeight: "94",
                                textSize: "5"
                            }
                        },
                    ],
                    players: [2]
                },
                "buildings": {
                    size: {w: 105, h: 60},
                    descriptions: {
                        "n_building": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/n_building.webp",
                            position: {x: 64, y: 50, type: "center"},
                            size: 50,
                            unrendered: true,
                        },
                        "n_trigger": {
                            type: "text",
                            content: "N. 201,  E. 935",
                            position: {x: 3.5, y: 13},
                            size: 2.5,
                            trigger: {type: "hover", target: "n_building"},
                            className: "font-bold hover:cursor-cell"
                        },
                        "c_building": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/c_building.webp",
                            position: {x: 64, y: 50, type: "center"},
                            size: 50,
                            unrendered: true,
                        },
                        "c_trigger": {
                            type: "text",
                            content: "O. 931, S. 326",
                            position: {x: 3.5, y: 23},
                            size: 2.5,
                            trigger: {type: "hover", target: "c_building"},
                            className: "font-bold hover:cursor-cell"
                        },
                        "e_building": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/e_building.webp",
                            position: {x: 64, y: 50, type: "center"},
                            size: 50,
                            unrendered: true,
                        },
                        "e_trigger": {
                            type: "text",
                            content: "O. 931,  N. 623",
                            position: {x: 3.5, y: 33},
                            size: 2.5,
                            trigger: {type: "hover", target: "e_building"},
                            className: "font-bold hover:cursor-cell"
                        },
                        "o_building": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/o_building.webp",
                            position: {x: 64, y: 50, type: "center"},
                            size: 50,
                            unrendered: true,
                        },
                        "o_trigger": {
                            type: "text",
                            content: "O. 238, N. 425",
                            position: {x: 3.5, y: 43},
                            size: 2.5,
                            trigger: {type: "hover", target: "o_building"},
                            className: "font-bold hover:cursor-cell"
                        },
                        "h_building": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/h_building.webp",
                            position: {x: 64, y: 50, type: "center"},
                            size: 50,
                            unrendered: true,
                        },
                        "h_trigger": {
                            type: "text",
                            content: "N. 238, E. 304",
                            position: {x: 3.5, y: 53},
                            size: 2.5,
                            trigger: {type: "hover", target: "h_building"},
                            className: "font-bold hover:cursor-cell"
                        },
                        "g_building": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/g_building.webp",
                            position: {x: 64, y: 50, type: "center"},
                            size: 50,
                            unrendered: true,
                        },
                        "g_trigger": {
                            type: "text",
                            content: "N. 525, O. 619",
                            position: {x: 3.5, y: 63},
                            size: 2.5,
                            trigger: {type: "hover", target: "g_building"},
                            className: "font-bold hover:cursor-cell"
                        },
                        "k_building": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/k_building.webp",
                            position: {x: 64, y: 50, type: "center"},
                            size: 50,
                            unrendered: true,
                        },
                        "k_trigger": {
                            type: "text",
                            content: "E. 148, S. 987",
                            position: {x: 3.5, y: 73},
                            size: 2.5,
                            trigger: {type: "hover", target: "k_building"},
                            className: "font-bold hover:cursor-cell"
                        },

                    },
                    players: [3]
                },
                "buildings&": {
                    size: {w: 105, h: 60},
                    descriptions: {
                        "u_building": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/u_building.webp",
                            position: {x: 64, y: 50, type: "center"},
                            size: 50,
                            unrendered: true,
                        },
                        "u_trigger": {
                            type: "text",
                            content: "N. 201, E. 893",
                            position: {x: 3.5, y: 13},
                            size: 2.5,
                            trigger: {type: "hover", target: "u_building"},
                            className: "font-bold hover:cursor-cell"
                        },
                        "i_building": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/i_building.webp",
                            position: {x: 64, y: 50, type: "center"},
                            size: 50,
                            unrendered: true,
                        },
                        "i_trigger": {
                            type: "text",
                            content: "O. 516, E. 425",
                            position: {x: 3.5, y: 23},
                            size: 2.5,
                            trigger: {type: "hover", target: "i_building"},
                            className: "font-bold hover:cursor-cell"
                        },
                        "m_building": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/m_building.webp",
                            position: {x: 64, y: 50, type: "center"},
                            size: 50,
                            unrendered: true,
                        },
                        "m_trigger": {
                            type: "text",
                            content: "S. 713, E. 425",
                            position: {x: 3.5, y: 33},
                            size: 2.5,
                            trigger: {type: "hover", target: "m_building"},
                            className: "font-bold hover:cursor-cell"
                        },
                        "r_building": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/r_building.webp",
                            position: {x: 64, y: 50, type: "center"},
                            size: 50,
                            unrendered: true,
                        },
                        "r_trigger": {
                            type: "text",
                            content: "O. 923, N. 425",
                            position: {x: 3.5, y: 43},
                            size: 2.5,
                            trigger: {type: "hover", target: "r_building"},
                            className: "font-bold hover:cursor-cell"
                        },
                        "p_building": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/p_building.webp",
                            position: {x: 64, y: 50, type: "center"},
                            size: 50,
                            unrendered: true,
                        },
                        "p_trigger": {
                            type: "text",
                            content: "S. 532, O. 765",
                            position: {x: 3.5, y: 53},
                            size: 2.5,
                            trigger: {type: "hover", target: "p_building"},
                            className: "font-bold hover:cursor-cell"
                        },
                        "x_building": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/x_building.webp",
                            position: {x: 64, y: 50, type: "center"},
                            size: 50,
                            unrendered: true,
                        },
                        "x_trigger": {
                            type: "text",
                            content: "N. 410, O. 666",
                            position: {x: 3.5, y: 63},
                            size: 2.5,
                            trigger: {type: "hover", target: "x_building"},
                            className: "font-bold hover:cursor-cell"
                        },
                        "g_building": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step3/g_building.webp",
                            position: {x: 64, y: 50, type: "center"},
                            size: 50,
                            unrendered: true,
                        },
                        "g_trigger": {
                            type: "text",
                            content: "N. 525, O. 619",
                            position: {x: 3.5, y: 73},
                            size: 2.5,
                            trigger: {type: "hover", target: "g_building"},
                            className: "font-bold hover:cursor-cell"
                        },

                    },
                    players: [4]
                },
            },
        },
        {
            component: <VideoWithInformation/>,
            baseHints: [],
            
            passwordModal: undefined,
            room: {
                buttons: [],
                templateOptions: {
                    "video": "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step4/video.webm",
                    "hint": "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step4/hint_p{player_number}.webp",
                    "button": "Se rendre à la salle des architectes"
                }
            }
        },
        {
            component: <BasicStepTemplate/>,
            baseHints: [MissingHintTemplate],
            passwordModal: {
                size: {w: 180, h: 60, max_w: 60},
                inputs: [
                    {
                        label: "1",
                        answer: "E",
                        player: 4,
                        textSize: 8,
                        height: 12
                    },
                    {
                        label: "2",
                        answer: "N",
                        player: 2,
                        textSize: 8,
                        height: 12
                    },
                    {
                        label: "3",
                        answer: "I",
                        player: 1,
                        textSize: 8,
                        height: 12
                    },
                    {
                        label: "4",
                        answer: "G",
                        player: 3,
                        textSize: 8,
                        height: 12
                    },
                    {
                        label: "5",
                        answer: "M",
                        player: 1,
                        textSize: 8,
                        height: 12
                    },
                    {
                        label: "6",
                        answer: "A",
                        player: 2,
                        textSize: 8,
                        height: 12
                    },
                ],
                descriptions: [
                    {
                        type: "image",
                        content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/en/step5/forms.webp",
                        position: {x:0, y:10},
                        unrendered: true,
                        size: 50
                    }
                ],
                content: (form, onChange) => <PasswordStep1 form={form} onChange={onChange} />,
                forceValidateButton: true
            },
            room: {
                backgroundImage: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/background_spair{sandwich_pair_number}.webp",
                buttons: [
                    {
                        type: "invisible",
                        size: {w: 31.7, h: 35.5},
                        action: {
                            type: "modal",
                            target: "answer",
                        },
                        position: {x: 8, y: 4},
                        players: [1, 4]
                    },
                    {
                        type: "invisible",
                        size: {w: 12, h: 16},
                        action: {
                            type: "password_modal"
                        },
                        position: {x: 44, y: 42},
                        players: [1, 4]
                    },
                    {
                        type: "invisible",
                        size: {w: 25.3, h: 29},
                        action: {
                            type: "modal",
                            target: "dummy2",
                        },
                        position: {x: 67.5, y: 26},
                        players: [1, 4],
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 22.6, h: 13},
                        action: {
                            type: "modal",
                            target: "dummy1",
                        },
                        position: {x: 60, y: 73.8},
                        players: [1, 4],
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 26, h: 29},
                        action: {
                            type: "modal",
                            target: "dummy2",
                        },
                        position: {x: 5.8, y: 6},
                        players: [2, 3],
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 27, h: 23.2},
                        action: {
                            type: "modal",
                            target: "dummy1",
                        },
                        position: {x: 37.8, y: 7.5},
                        players: [2, 3],
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 24.9, h: 26},
                        action: {
                            type: "modal",
                            target: "answer",
                        },
                        position: {x: 72.8, y: 4},
                        players: [2, 3]
                    },
                    {
                        type: "invisible",
                        size: {w: 12.5, h: 16},
                        action: {
                            type: "password_modal"
                        },
                        position: {x: 20, y: 41.5},
                        players: [2, 3]
                    },
                    {
                        type: "invisible",
                        size: {w: 25, h: 29.5},
                        action: {
                            type: "modal",
                            target: "dummy3",
                        },
                        position: {x: 59.3, y: 33.5},
                        players: [2, 3],
                        dummy: true
                    },
                ],
                timeBeforeVisible: 180
            },
            modals: {
                "dummy1": {
                    size: {w: 100, h: 60},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/dummy1.webp",
                            position: {x: 50, y: 47, type: "center"},
                            size: 100
                        },
                        {
                            type: "text",
                            content: "Rien d'intéressant sur ce croquis...",
                            position: {x: 50, y: 95, type: "center"},
                            size: 1.38
                        }
                    ]
                },
                "dummy2": {
                    size: {w: 100, h: 60},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/dummy2.webp",
                            position: {x: 50, y: 47, type: "center"},
                            size: 100
                        },
                        {
                            type: "text",
                            content: "Rien d'intéressant sur ce croquis...",
                            position: {x: 50, y: 95, type: "center"},
                            size: 1.38
                        }
                    ]
                },
                "dummy3": {
                    size: {w: 100, h: 63},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/dummy3.webp",
                            position: {x: 50, y: 45, type: "center"},
                            size: 100
                        },
                        {
                            type: "text",
                            content: "Rien d'intéressant sur ce croquis...",
                            position: {x: 50, y: 95, type: "center"},
                            size: 1.38
                        }
                    ]
                },
                "answer": {
                    size: {w: 120, h: 70},
                    descriptions: [
                        {
                            type: "background_image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/book.webp",
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p1_audio1_robot.mp3",
                            position: {x: 56, y: 17.5, type: "centerRight"},
                            size: 1.6
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p1_audio2_talons.mp3",
                            position: {x: 56, y: 30.5, type: "centerRight"},
                            size: 1.6
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p1_audio3_respiration.mp3",
                            position: {x: 56, y: 43.5, type: "centerRight"},
                            size: 1.6
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p1_audio4_bateau.mp3",
                            position: {x: 56, y: 56.5, type: "centerRight"},
                            size: 1.6
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p1_audio5_helicoptere.mp3",
                            position: {x: 56, y: 69.5, type: "centerRight"},
                            size: 1.6
                        },
                        {
                            type: "special_text",
                            content: "Grande Mosquée de Ni{style:color:#ff0000}a{/style}mey",
                            position: {x: 58, y: 17.5, type: "centerLeft"},
                            size: 1.38
                        },
                        {
                            type: "special_text",
                            content: "Fer{style:color:#ff0000}m{/style}e Youyou",
                            position: {x: 58, y: 30.5, type: "centerLeft"},
                            size: 1.38
                        },
                        {
                            type: "special_text",
                            content: "Mosquée Cité Caiss{style:color:#ff0000}e{/style}",
                            position: {x: 58, y: 43.5, type: "centerLeft"},
                            size: 1.38
                        },
                        {
                            type: "special_text",
                            content: "Tiguidi{style:color:#ff0000}t{/style}",
                            position: {x: 58, y: 56.5, type: "centerLeft"},
                            size: 1.38
                        },
                        {
                            type: "special_text",
                            content: "Musée nat{style:color:#ff0000}i{/style}onal Boubou-Hama",
                            position: {x: 58, y: 69.5, type: "centerLeft"},
                            size: 1.38
                        }
                    ],
                    players: [1]
                },
                "answer&": {
                    size: {w: 120, h: 70},
                    descriptions: [
                        {
                            type: "background_image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/book.webp",
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p4_audio1_vaisseau.mp3",
                            position: {x: 56, y: 17.5, type: "centerRight"},
                            size: 1.6
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p4_audio2_boing.mp3",
                            position: {x: 56, y: 30.5, type: "centerRight"},
                            size: 1.6
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p4_audio3_alarme.mp3",
                            position: {x: 56, y: 43.5, type: "centerRight"},
                            size: 1.6
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p4_audio4_trainroule.mp3",
                            position: {x: 56, y: 56.5, type: "centerRight"},
                            size: 1.6
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p4_audio5_fantome.mp3",
                            position: {x: 56, y: 69.5, type: "centerRight"},
                            size: 1.6
                        },
                        {
                            type: "special_text",
                            content: "Pont K{style:color:#ff0000}e{/style}nnedy",
                            position: {x: 58, y: 17.5, type: "centerLeft"},
                            size: 1.38
                        },
                        {
                            type: "special_text",
                            content: "Te{style:color:#ff0000}n{/style}ere Hotel",
                            position: {x: 58, y: 30.5, type: "centerLeft"},
                            size: 1.38
                        },
                        {
                            type: "special_text",
                            content: "Parc national du {style:color:#ff0000}W{/style}",
                            position: {x: 58, y: 43.5, type: "centerLeft"},
                            size: 1.38
                        },
                        {
                            type: "special_text",
                            content: "Massif de te{style:color:#ff0000}r{/style}mit",
                            position: {x: 58, y: 56.5, type: "centerLeft"},
                            size: 1.38
                        },
                        {
                            type: "special_text",
                            content: "Sultanat du Dama{style:color:#ff0000}g{/style}aram",
                            position: {x: 58, y: 69.5, type: "centerLeft"},
                            size: 1.38
                        }
                    ],
                    players: [4]
                },
                "answer&&": {
                    size: {w: 100, h: 70},
                    descriptions: [
                        {
                            type: "background_image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p2_hint.webp",
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p4_audio1_vaisseau.mp3",
                            position: {x: 32, y: 40.5},
                            size: 2
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p4_audio2_boing.mp3",
                            position: {x: 32, y: 79},
                            size: 2
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p4_audio5_fantome.mp3",
                            position: {x: 68, y: 72},
                            size: 2
                        }
                    ],
                    players: [2]
                },
                "answer&&&": {
                    size: {w: 100, h: 70},
                    descriptions: [
                        {
                            type: "background_image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p3_hint.webp",
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p1_audio5_helicoptere.mp3",
                            position: {x: 75.8, y: 22.5},
                            size: 2
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p1_audio2_talons.mp3",
                            position: {x: 60, y: 26},
                            size: 2
                        },
                        {
                            type: "minimal_audio_button",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step5/p1_audio1_robot.mp3",
                            position: {x: 49.2, y: 48},
                            size: 2
                        }
                    ],
                    players: [3]
                },
            },
        },
        {
            component: <VideoWithInformation/>,
            baseHints: [],
            
            passwordModal: undefined,
            room: {
                buttons: [],
                templateOptions: {
                    "video": "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step6/video.webm",
                    "hint": "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step6/hint_p{player_number}.webp",
                    "button": "Aller à l'unité de décodage"
                }
            }
        },
        {
            component: <BasicStepTemplate/>,
            baseHints: [MissingHintTemplate],
            passwordModal: {
                size: {w: 100, h: 50},
                inputs: [
                    {
                        label: "N",
                        answer: "CELLULES",
                        placeHolder: "Quel est le nom de cette salle?",
                        auto_check: true,
                        player: 1,
                        position: {x: 46.5, y: 35, type: "centerLeft"},
                        size: 50,
                        height: 2.8,
                        textSize: 1.3
                    },
                    {
                        label: "I",
                        answer: "PLATEAU DE CONTROLE",
                        placeHolder: "Quel est le nom de cette salle?",
                        auto_check: true,
                        player: 2,
                        position: {x: 46.5, y: 50, type: "centerLeft"},
                        size: 50,
                        height: 2.8,
                        textSize: 1.3
                    },
                    {
                        label: "G",
                        answer: "ARCHIVES SECRETES",
                        placeHolder: "Quel est le nom de cette salle?",
                        auto_check: true,
                        player: 4,
                        position: {x: 46.5, y: 65, type: "centerLeft"},
                        size: 50,
                        height: 2.8,
                        textSize: 1.3
                    },
                    {
                        label: "A",
                        answer: "VESTIAIRES",
                        placeHolder: "Quel est le nom de cette salle?",
                        auto_check: true,
                        player: 3,
                        position: {x: 46.5, y: 80, type: "centerLeft"},
                        size: 50,
                        height: 2.8,
                        textSize: 1.3
                    },
                ],
                descriptions: [
                    {
                        type: "image",
                        content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/password_image.webp",
                        position: {x: 0, y: 55, type: "centerLeft"},
                        size: 35
                    },
                    {
                        type: "special_text",
                        content: "{style:bold}N{/style}",
                        position: {x: 43, y: 35, type: "center"},
                        size: 2,
                    },
                    {
                        type: "special_text",
                        content: "{style:bold}I{/style}",
                        position: {x: 43, y: 50, type: "center"},
                        size: 2
                    },
                    {
                        type: "special_text",
                        content: "{style:bold}G{/style}",
                        position: {x: 43, y: 65, type: "center"},
                        size: 2
                    },
                    {
                        type: "special_text",
                        content: "{style:bold}A{/style}",
                        position: {x: 43, y: 80, type: "center"},
                        size: 2
                    }
                ],
            },
            room: {
                backgroundImage: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/background.webp",
                buttons: [
                    {
                        type: "invisible",
                        size: {w: 14.5, h: 17},
                        action: {
                            type: "modal",
                            target: "key",
                        },
                        position: {x: 5, y: 21}
                    },
                    {
                        type: "invisible",
                        size: {w: 9.3, h: 11.8},
                        action: {
                            type: "modal",
                            target: "code",
                        },
                        position: {x: 17.5, y: 60}
                    },
                    {
                        type: "invisible",
                        size: {w: 4.6, h: 9},
                        action: {
                            type: "password_modal",
                        },
                        position: {x: 94.1, y: 66}
                    },
                ],
                timeBeforeVisible: 180
            },
            modals: {
                "key": {
                    size: {w: 80, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/a_cipher.webp",
                            position: {x: 50, y: 48, type: "center"},
                            size: 95
                        }
                    ],
                    players: [1]
                },
                "key&": {
                    size: {w: 80, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/n_cipher.webp",
                            position: {x: 50, y: 48, type: "center"},
                            size: 95
                        }
                    ],
                    players: [2]
                },
                "key&&": {
                    size: {w: 80, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/n_decipher.webp",
                            position: {x: 50, y: 48, type: "center"},
                            size: 95
                        }
                    ],
                    players: [3]
                },
                "key&&&": {
                    size: {w: 80, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/a_decipher.webp",
                            position: {x: 50, y: 48, type: "center"},
                            size: 95
                        }
                    ],
                    players: [4]
                },
                "code": {
                    size: {w: 70, h: 80},
                    descriptions: {
                        "background": {
                            type: "background_image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/background_p1.png",
                        },
                        "appear": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/appear_p1.jpg",
                            position: {x: 50, y: 60, type: "center"},
                            size: 85,
                            unrendered: true
                        },
                        "hover": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/hover_p1.jpeg",
                            position: {x: 50, y: 80, type: "center"},
                            size: 85,
                            trigger: {
                                type: "hover",
                                target: "appear"
                            }
                        }
                    },
                    players: [1]
                },
                "code&": {
                    size: {w: 70, h: 80},
                    descriptions: {
                        "background": {
                            type: "background_image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/background_p2.png",
                        },
                        "appear": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/appear_p2.jpg",
                            position: {x: 50, y: 60, type: "center"},
                            size: 98,
                            unrendered: true
                        },
                        "hover": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/hover_p2.jpeg",
                            position: {x: 50, y: 80, type: "center"},
                            size: 98,
                            trigger: {
                                type: "hover",
                                target: "appear"
                            }
                        }
                    },
                    players: [2]
                },
                "code&&": {
                    size: {w: 70, h: 80},
                    descriptions: {
                        "background": {
                            type: "background_image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/background_p3.png",
                        },
                        "appear": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/appear_p3.jpg",
                            position: {x: 50, y: 60, type: "center"},
                            size: 98,
                            unrendered: true
                        },
                        "hover": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/hover_p2.jpeg",
                            position: {x: 50, y: 80, type: "center"},
                            size: 98,
                            trigger: {
                                type: "hover",
                                target: "appear"
                            }
                        }
                    },
                    players: [3]
                },
                "code&&&": {
                    size: {w: 70, h: 80},
                    descriptions: {
                        "background": {
                            type: "background_image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/background_p4.png",
                        },
                        "appear": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/appear_p4.jpg",
                            position: {x: 50, y: 60, type: "center"},
                            size: 85,
                            unrendered: true
                        },
                        "hover": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step7/hover_p1.jpeg",
                            position: {x: 50, y: 80, type: "center"},
                            size: 85,
                            trigger: {
                                type: "hover",
                                target: "appear"
                            }
                        }
                    },
                    players: [4]
                },
            },
        },
        {
            component: <VideoWithInformation/>,
            baseHints: [],
            
            passwordModal: undefined,
            room: {
                buttons: [],
                templateOptions: {
                    "video": "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step8/video.webm",
                    "hint": "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step8/hint_p{player_number}.webp",
                    "button": "Aller à la salle de travail"
                }
            }
        },
        {
            component: <BasicStepTemplate/>,
            baseHints: [MissingHintTemplate],
            adaptations: ["daltonien"],
            passwordModal: {
                validateButton: "Envoyer le plan d'action chiffré",
                size: {w: 120, h: 60},
                defaultInput: {
                    label: "default",
                    answer: "default",
                    type: "select",
                    options: [
                        "",
                        "Allez au plateau de contrôle pour trafiquer les caméras afin d'éviter qu'on vous repère.",
                        "Pendant leur service, les soldats se chuchotent des confidences précieuses sur le pont des gardes. Tendez l'oreille !",
                        "Récupérez une tenue aux vestiaires pour vous fondre dans leur troupe.",
                        "Obtenez des renseignements plus précis sur notre agent en allant fouiller les archives secrètes.",
                        "Infiltrez le terrain d'entraînement pour évaluer leur nombre. Attention, tous les soldats Nigériens sifflotent le même air.",
                        "Pause déjeuner : rejoignez le reste de l'organisation à la cantine. Ne paniquez pas, vous avez l'air d'un vrai soldat.",
                        "C'est l'heure de la sieste ! Profitez de ce moment pour foncer aux cellules des prisonniers et libérer notre agent.",
                        "Faites le plein de munitions au cas où la situation dégénère. L'artillerie est remplie d'équipements.",
                    ],
                    placeHolder: "Quelle action doit être entreprise ?",
                    size: 88,
                    height: 3,
                    textSize: 1.1
                },
                inputs: [
                    {
                        label: "15",
                        answer: "Allez au plateau de contrôle pour trafiquer les caméras afin d'éviter qu'on vous repère.",
                        player: 1,
                        position: {x: 8, y: 22, type: "centerLeft"},
                    },
                    {
                        label: "16", 
                        answer: "Pendant leur service, les soldats se chuchotent des confidences précieuses sur le pont des gardes. Tendez l'oreille !",
                        player: 2,
                        position: {x: 8, y: 32, type: "centerLeft"},
                    },
                    {
                        label: "78",
                        answer: "Faites le plein de munitions au cas où la situation dégénère. L'artillerie est remplie d'équipements.",
                        player: 3,
                        position: {x: 8, y: 42, type: "centerLeft"},
                    },
                    {
                        label: "2",
                        answer: "Obtenez des renseignements plus précis sur notre agent en allant fouiller les archives secrètes.",
                        player: 4,
                        position: {x: 8, y: 52, type: "centerLeft"},
                    },
                    {
                        label: "8",
                        answer: "Récupérez une tenue aux vestiaires pour vous fondre dans leur troupe.",
                        player: 3,
                        position: {x: 8, y: 62, type: "centerLeft"},
                    },
                    {
                        label: "3",
                        answer: "Infiltrez le terrain d'entraînement pour évaluer leur nombre. Attention, tous les soldats Nigériens sifflotent le même air.",
                        player: 1,
                        position: {x: 8, y: 72, type: "centerLeft"},
                    },
                    {
                        label: "14",
                        answer: "Pause déjeuner : rejoignez le reste de l'organisation à la cantine. Ne paniquez pas, vous avez l'air d'un vrai soldat.",
                        player: 4,
                        position: {x: 8, y: 82, type: "centerLeft"},
                    },
                    {
                        label: "44",
                        answer: "C'est l'heure de la sieste ! Profitez de ce moment pour foncer aux cellules des prisonniers et libérer notre agent.",
                        player: 2,
                        position: {x: 8, y: 92, type: "centerLeft"},
                    },
                ],
                descriptions: [
                    {
                        type: "background_image",
                        content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/password_background.webp",
                    },
                    {
                        type: "special_text",
                        content: "{style:bold,color:#253570}ACTION PLAN{/style}",
                        position: {x: 50, y: 3, type: "topCenter"},
                        size: 4
                    },
                    {
                        type: "special_text",
                        content: "{style:bold,color:#253570}15{/style}",
                        position: {x: 5, y: 22, type: "centerRight"},
                        size: 1.8
                    },
                    {
                        type: "special_text",
                        content: "{style:bold,color:#253570}16{/style}",
                        position: {x: 5, y: 32, type: "centerRight"},
                        size: 1.8
                    },
                    {
                        type: "special_text",
                        content: "{style:bold,color:#253570}78{/style}",
                        position: {x: 5, y: 42, type: "centerRight"},
                        size: 1.8
                    },
                    {
                        type: "special_text",
                        content: "{style:bold,color:#253570}2{/style}",
                        position: {x: 5, y: 52, type: "centerRight"},
                        size: 1.8
                    },
                    {
                        type: "special_text",
                        content: "{style:bold,color:#253570}8{/style}",
                        position: {x: 5, y: 62, type: "centerRight"},
                        size: 1.8
                    },
                    {
                        type: "special_text",
                        content: "{style:bold,color:#253570}3{/style}",
                        position: {x: 5, y: 72, type: "centerRight"},
                        size: 1.8
                    },
                    {
                        type: "special_text",
                        content: "{style:bold,color:#253570}14{/style}",
                        position: {x: 5, y: 82, type: "centerRight"},
                        size: 1.8
                    },
                    {
                        type: "special_text",
                        content: "{style:bold,color:#253570}44{/style}",
                        position: {x: 5, y: 92, type: "centerRight"},
                        size: 1.8
                    },
                ],
                content: (form, onChange) => {
                    return <Stage9password form={form} onChange={onChange}/>;
                }
            },
            room: {
                backgroundImage: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/background.webp",
                buttons: [
                    {
                        type: "invisible",
                        size: {w: 40.4, h: 38.5},
                        action: {
                            type: "password_modal",
                        },
                        position: {x: 16, y: 3.9}
                    },
                    {
                        type: "invisible",
                        size: {w: 8.5, h: 16.5},
                        action: {
                            type: "modal",
                            target: "portrait",
                        },
                        position: {x: 0.8, y: 44.5},
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 7.8, h: 7},
                        action: {
                            type: "modal",
                            target: "badge",
                        },
                        position: {x: 36, y: 66.7},
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 3.1, h: 5.8},
                        action: {
                            type: "modal",
                            target: "angel",
                        },
                        position: {x: 45.8, y: 68.5}
                    },
                    {
                        type: "invisible",
                        size: {w: 6.4, h: 9.5},
                        action: {
                            type: "modal",
                            target: "handcuffs",
                        },
                        position: {x: 41.3, y: 79.7},
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 17, h: 10.2},
                        action: {
                            type: "modal",
                            target: "empty_drawer",
                        },
                        position: {x: 69.7, y: 43.5},
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 3.4, h: 8.5},
                        action: {
                            type: "modal",
                            target: "lock",
                        },
                        position: {x: 76.2, y: 61.5},
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 6.7, h: 9.5},
                        action: {
                            type: "modal",
                            target: "plant",
                        },
                        position: {x: 84, y: 90.2},
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 6.7, h: 10.5},
                        action: {
                            type: "modal",
                            target: "sailboat",
                        },
                        position: {x: 75, y: 27.8}
                    },
                    {
                        type: "invisible",
                        size: {w: 9.1, h: 26},
                        action: {
                            type: "modal",
                            target: "bin",
                        },
                        position: {x: 90.8, y: 65.7},
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 5.5, h: 16.8},
                        action: {
                            type: "modal",
                            target: "flower",
                        },
                        position: {x: 81.7, y: 21}
                    },
                    {
                        type: "invisible",
                        size: {w: 16.3, h: 27.3},
                        action: {
                            type: "modal",
                            target: "clock",
                        },
                        position: {x: 82.6, y: 0},
                        dummy: true
                    },
                    {
                        type: "invisible",
                        size: {w: 11.6, h: 19},
                        action: {
                            type: "modal",
                            target: "lamp",
                        },
                        position: {x: 53.2, y: 7}
                    },
                    {
                        type: "invisible",
                        size: {w: 11.7, h: 14.2},
                        action: {
                            type: "modal",
                            target: "diploma",
                        },
                        position: {x: 69, y: 2.8},
                        dummy: true
                    },
                ],
                timeBeforeVisible: 180
            },
            modals: {
                "portrait": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/location_p1.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                    players: [1]
                },
                "portrait&": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/location_p2.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                    players: [2]
                },
                "portrait&&": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/location_p3.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                    players: [3]
                },
                "portrait&&&": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/location_p4.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                    players: [4]
                },
                "badge": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/badge.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                },
                "angel": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/gardian_angel.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                    players: [1, 3, 4],
                    dummy: true
                },
                "angel&": {
                    size: {w: 60, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/hint_p2.png",
                            position: {x: 50, y: 50, type: "center"},
                            size: 90
                        }
                    ],
                    adaptation: [],
                    players: [2]
                },
                "angel&&": {
                    size: {w: 60, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/hint_p2_daltonien.png",
                            position: {x: 50, y: 50, type: "center"},
                            size: 90
                        }
                    ],
                    adaptation: ["daltonien"],
                    players: [2]
                },
                "handcuffs": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/handcuffs.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                },
                "lock": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/locked_drawer.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                },
                "empty_drawer": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/empty_drawer.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                },
                "bin": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/dustbin.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                },
                "clock": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/clock.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                },
                "diploma": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/diploma.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                },
                "flower": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/flower.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                    players: [1, 2, 3],
                    dummy: true
                },
                "flower&": {
                    size: {w: 120, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/hint_p4.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 90
                        }
                    ],
                    players: [4]
                },
                "lamp": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/light_bulb.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                    players: [1, 2, 4],
                    dummy: true
                },
                "lamp&": {
                    size: {w: 120, h: 70},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/hint_p3.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 90
                        }
                    ],
                    players: [3]
                },
                "plant": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/plant.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                },
                "sailboat": {
                    size: {w: 70, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/sailboat.webp",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                    players: [2, 3, 4],
                    dummy: true
                },
                "sailboat&": {
                    size: {w: 85, h: 80},
                    descriptions: [
                        {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/hint_p1.png",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100
                        }
                    ],
                    adaptation: [],
                    players: [1]
                },
                "sailboat&&": {
                    size: {w: 85, h: 80},
                    descriptions: {
                        "colors": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/hint_p1.png",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100,
                            trigger: {
                                type: "hover_switch",
                                target: "numbers",
                                self: "colors"
                            }
                        },
                        "numbers": {
                            type: "image",
                            content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step9/hint_p1_daltonien.png",
                            position: {x: 50, y: 50, type: "center"},
                            size: 100,
                            unrendered: true,
                            trigger: {
                                type: "unhover_switch",
                                target: "colors",
                                self: "numbers"
                            }
                        }
                    },
                    adaptation: ["daltonien"],
                    players: [1]
                },
            }
        },
        {
            component: <VideoWithInformation/>,
            baseHints: [],
            
            passwordModal: undefined,
            room: {
                buttons: [],
                templateOptions: {
                    "video": "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step10/video.webm",
                    "hint": "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step10/hint_p{player_number}.webp",
                    "button": "Aller à la salle de décision"
                }
            }
        },
        {
            component: <BasicStepTemplate/>,
            baseHints: [MissingPlayers11],
            description: {
                hints: [],
                solution: {
                    "penalty": 300
                },
            },
            passwordModal: {
                size: {w: 120, h: 60},
                backgroundColor: "#ECEBE0",
                validateButton: "Envoyer le plan d'action chiffré",
                descriptions: [
                    {
                        type: "image",
                        content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/en/step11/sticker1.webp",
                        position: {x: 18, y: 18, type: "centerRight"},
                        size: 10
                    },
                    {
                        type: "image",
                        content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/en/step11/sticker2.webp",
                        position: {x: 18, y: 41, type: "centerRight"},
                        size: 10
                    },
                    {
                        type: "image",
                        content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/en/step11/sticker3.webp",
                        position: {x: 18, y: 66, type: "centerRight"},
                        size: 9
                    },
                    {
                        type: "image",
                        content: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/en/step11/sticker4.webp",
                        position: {x: 18, y: 92, type: "centerRight"},
                        size: 10
                    }
                ],
                inputs: [
                    {
                        label: "answer",
                        answer: "EMAIL",
                        position: {x: 21, y: 18, type: "centerLeft"},
                        placeHolder: "Comment doit-on envoyer le plan d'action ?",
                        auto_check: true,
                        player: 1,
                        size: 75,
                        height: 3.3,
                        textSize: 1.9,
                        centered: true
                    },
                    {
                        label: "answer2",
                        answer: "PYTHON",
                        position: {x: 21, y: 41, type: "centerLeft"},
                        size: 75,
                        height: 3.3,
                        textSize: 1.9,
                        placeHolder: "Quel langage doit-on utiliser pour chiffrer le plan d'action ?",
                        auto_check: true,
                        player: 2,
                        centered: true
                    },
                    {
                        label: "answer3",
                        answer: "AGENTS",
                        position: {x: 21, y: 67, type: "centerLeft"},
                        size: 75,
                        height: 3.3,
                        textSize: 1.9,
                        placeHolder: "Qui va utiliser le plan d'action ?",
                        auto_check: true,
                        player: 3,
                        centered: true
                    },
                    {
                        label: "answer4",
                        answer: "71-95",
                        position: {x: 21, y: 92, type: "centerLeft"},
                        size: 75,
                        height: 3.3,
                        textSize: 1.9,
                        placeHolder: "Quel est le numéro du ClanD à sauver ?",
                        auto_check: true,
                        player: 4,
                        centered: true
                    }
                ],
            },
            room: {
                backgroundImage: "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step11/background.webp",
                buttons: [
                    {
                        type: "invisible",
                        size: {w: 4, h: 9.5},
                        action: {
                            type: "password_modal",
                        },
                        position: {x: 19, y: 26}
                    },
                ],
                timeBeforeVisible: 180,
            },
        },
        {
            component: <Step12/>,
            baseHints: [],
            
            passwordModal: undefined,
            room: {
                buttons: [],
                templateOptions: {
                    "video": "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/fr/step12/video.webm",
                    "background_image": "https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/en/step12/background.webp",
                    "texte": "Les Wokies peuvent être fiers de vous. Vous avez établi un plan d'action cohérent pour résoudre la mission qui vous a été confiée par la DGSE !",
                    "button": "Terminer la mission"
                }
            }
        },
    ],
};

export default dgseFrScenario;