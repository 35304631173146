export function shuffle(array: any[]) {
    return array.sort(() => Math.random() - 0.5);
}

export function shuffleOptions(options: any[] | undefined, getString: (option: any) => string) {
    if (!options) return [];
    return options.sort((a, b) => {
        if (getString(a) === "") return -1;
        if (getString(b) === "") return 1;
        return Math.random() - 0.5;
    });
}
