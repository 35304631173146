import { Flex, Modal } from "antd";
import TextCard from "../components/TextCard";
import TypeService from "../../../shared/services/TypeService";
import { useEffect, useMemo, useRef, useState } from "react";
import MinimalAudioButton from "../components/MinimalAudioButton";
import CustomText from "../components/CustomText";
import ImageRatioBackground from "../../../shared/components/image/ImageRatioBackground";
import type { ModalParameters, ModalDescription, ModalTrigger } from "../../../shared/models/Templates";
import StyleService from "../../../shared/services/templates/StyleService";
import AppSpinner from "../../../shared/components/spinner/AppSpinner";
import modalSize from "../../../shared/services/utils/modalSize";

type BasicModalTemplateProps = {
    modal: ModalParameters;
    children?: React.ReactNode;
    footer?: React.ReactNode;
    title?: React.ReactNode;
    unclosable?: boolean;
    noRender?: boolean;
}

export default function BasicModalTemplate(props: BasicModalTemplateProps & {open: boolean, setOpen?: (open: boolean) => void}) {
    const type: string | undefined = useMemo(() => TypeService.modalDescriptionType(props.modal.descriptions), [props.modal.descriptions]);
    const [size, setSize] = useState<{w: string, h: string, fontSize: string} | undefined>(undefined);
    useEffect(() => {
        const handleResize = () => {
            setSize(modalSize(props.modal.size));
        }

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [props.modal.size]);

    const exit = () => {
        if (props.unclosable) {
            return;
        }
        const audios = document.getElementsByTagName('audio');
        for (let i = 0; i < audios.length; i++) {
            audios[i].pause();
        }
        props.setOpen?.(false);
    }

    return (
        <Modal
            open={props.open}
            onCancel={exit}
            width={size?.w}
            footer={props.footer ?? null}
            title={props.title ?? <></>}
            closable={!props.unclosable}
            styles={{
                body: {height: size?.h},
                header: {backgroundColor: props.modal.backgroundColor ?? '#ffffff'},
                content: {backgroundColor: props.modal.backgroundColor ?? '#ffffff'},
            }}
        >
            <BasicModalContent {...props} type={type} fontSize={size?.fontSize}/>
        </Modal>
    );
}

export function BasicModalContent(props: BasicModalTemplateProps & {type: string | undefined, fontSize?: string}) : JSX.Element {
    const [renderedItems, setRenderedItems] = useState<ModalDescription[]>([]);
    const absolute = useMemo(() => props.type !== "single" ? "absolute" : "", [props.type]);
    const audioRegistry = new Set<HTMLAudioElement | (typeof Audio)>();

    const updateRender = () => {
        let res;
        if (props.type === undefined) {
            setRenderedItems([])
            return;
        } else if (props.type === "array") {
            res = props.modal.descriptions as ModalDescription[]
        } else {
            res = Object.values(props.modal.descriptions as {[key: string]: ModalDescription})
        }
        setRenderedItems(res);
    }

    useEffect(() => {
        updateRender();
    }, [props.modal.descriptions, props.type]);

    const onMouseEnter = (trigger: ModalTrigger | undefined) => {
        if (!trigger) return;
        if (!props.modal.descriptions || TypeService.modalDescriptionType(props.modal.descriptions) !== "dictionary") return;
        
        switch (trigger.type) {
            case "hover":
                (props.modal.descriptions as {[key: string]: ModalDescription})[trigger.target].unrendered = false;
                break;
            case "unhover":
                (props.modal.descriptions as {[key: string]: ModalDescription})[trigger.target].unrendered = true;
                break;
            case "hover_switch":
                (props.modal.descriptions as {[key: string]: ModalDescription})[trigger.target].unrendered = false;
                (props.modal.descriptions as {[key: string]: ModalDescription})[trigger.self || ""].unrendered = true;
                break;
        }
        updateRender();
    }
    
    const onMouseLeave = (trigger: ModalTrigger | undefined) => {
        if (!trigger) return;
        if (!props.modal.descriptions || TypeService.modalDescriptionType(props.modal.descriptions) !== "dictionary") return;
        
        switch (trigger.type) {
            case "hover":
                (props.modal.descriptions as {[key: string]: ModalDescription})[trigger.target].unrendered = true;
                break;
            case "unhover":
                (props.modal.descriptions as {[key: string]: ModalDescription})[trigger.target].unrendered = false;
                break;
            case "unhover_switch":
                (props.modal.descriptions as {[key: string]: ModalDescription})[trigger.target].unrendered = false;
                (props.modal.descriptions as {[key: string]: ModalDescription})[trigger.self || ""].unrendered = true;
                break;
        }
        updateRender();
    }

    const backgroundImage = useMemo(() => {
        return Object.values(renderedItems).find(description => description.type === "background_image");
    }, [renderedItems]);

    const subContentItems = useMemo(() => {
        if (backgroundImage) {
            return Object.values(renderedItems).filter(description => description.type !== "background_image");
        }
        return [];
    }, [backgroundImage, renderedItems]);

    if (!renderedItems || renderedItems.length === 0 || props.noRender) return <div className="w-full h-full" style={{fontSize: props.fontSize}}>{props.children ?? <AppSpinner size={"large"}/>}</div>;
    const getContent = (items: ModalDescription[], subItem: boolean = false) : React.ReactNode[] => [
        ...items.map(description => {
            const audioRef = (element: HTMLAudioElement) => {
                if (element) {
                    element.addEventListener("play", () => stopOtherAudios(element, audioRegistry));
                }
            }
            const globalProps = {
                onMouseEnter: () => onMouseEnter(description.trigger),
                onMouseLeave: () => onMouseLeave(description.trigger),
                className: `${description.className ?? ""} ${subItem ? "absolute" : absolute}`,
                style: {
                    left: `${description.position?.x ?? 0}%`,
                    top: `${description.position?.y ?? 0}%`,
                    transform: StyleService.getTranslate(description.position?.type),
                    visibility: description.unrendered ? "hidden" as const : "visible" as const
                }
            }
            if (description.type === "text_card" && props.type !== "single") {
                return (
                    <TextCard
                        {...globalProps}
                        text={description.content}
                        options={description.templateOptions}
                        fill={false}
                    />
                )
            }
            return (
                <Flex 
                    {...globalProps}
                    className={`${globalProps.className} justify-center`}
                    style={{
                        ...globalProps.style,
                        width: ["image", "audio"].includes(description.type) ? `${description.size}%` : undefined,
                    }}
                >
                    {description.type === "image" && <img className={`${props.type === "single" && "px-4"}`} src={description.content} width={`100%`}/>}
                    {description.type === "text" && <span style={{fontSize: `${description.size}em`, lineHeight: 1, width: 'max-content'}}>{description.content}</span>}
                    {description.type === "special_text" && <CustomText style={{fontSize: `${description.size}em`, lineHeight: 1, width: 'max-content'}} content={description.content} />}
                    {description.type === "audio" && <audio ref={audioRef} src={description.content} controls className={`${absolute} w-full`} />}
                    {description.type === "minimal_audio_button" && <MinimalAudioButton callbackPlay={(el: HTMLAudioElement) => stopOtherAudios(el, audioRegistry)} audioPath={description.content} size={description.size} />}
                    {description.type === "text_card" && <TextCard text={description.content} options={description.templateOptions} fill={props.type === "single"} className={`${description.className ?? ""} absolute`} />}
                </Flex>
            )
        }),
        props.children ?? []
    ]
    
    if (backgroundImage) {
        return (
            <ImageRatioBackground bgImage={backgroundImage.content} modal={true} style={{fontSize: props.fontSize}}>
                {getContent(subContentItems, true)}
            </ImageRatioBackground>
        )
    }

    return <div className="relative w-full h-full" style={{fontSize: props.fontSize}}>{getContent(renderedItems)}</div>;
}


function stopOtherAudios(currentAudio: HTMLAudioElement, audioRegistry: Set<HTMLAudioElement | typeof Audio>) {
    const allAudios = Array.from(audioRegistry);
    audioRegistry.add(currentAudio);
    
    for (const audio of allAudios) {
        if (audio && audio !== currentAudio) {
            (audio as HTMLAudioElement).pause();
            audioRegistry.delete(audio);
        }
    }
}