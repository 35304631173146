import {Form, FormInstance, Input} from "antd";
import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import {InputValue} from "../../models/Team/InputValue";
import {Player} from "../../models/Player/Player";
import SyncService from "../../services/sync/SyncService";
import t from "../../../configs/i18n";
import { useGameContext } from "../../../pages/session_game/context/context.game";
import scenarios from "../../../scenarios/scenarios";

interface SyncronizedInputProps {
    id : string;
    player: Player | undefined;
    playersTeam: Player[];
    form?: FormInstance;
    number?: number;
    playersNumbers?: number[];
    className?: string;
    addonAfter?: string | null;
    placeHolder?: string;
    forcePlacholder?: boolean;
    getValue?: (value: string) => void;
    size?: "large" | "middle" | "small";
    customSize?: number | undefined;
    onChange?: (value: string, id: string) => void;
    isDisabled?: boolean;
    color?: string;
    noPadding?: boolean;
    maxLength?: number;
    fetchCallback?: () => void;
    textSize?: number;
}



export default function SyncronizedInput({id, player, playersTeam, number, playersNumbers, form, className = "", addonAfter = null, placeHolder = "", size = "middle", customSize = undefined, onChange = () => {}, isDisabled = false, forcePlacholder = false, color, noPadding, maxLength, fetchCallback, textSize} : SyncronizedInputProps) {
    const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const context = useGameContext();
    const [value, setValue] = useState<string>("");
    const classNameMemo = useMemo(() => {
        if (context.session?.scenario && scenarios[context.session.scenario].solo) {
            return `${className} ${context.solution?.solutionOn ? "disabled:bg-green-500 hover:cursor-not-allowed disabled:text-white" : ""}`
        }
        return className;
    }, [context.solution?.solutionOn, className])

    const loadValues = async () => {
        await InputValue.byId(id, false).then(input => {
            if (!input || context.solution?.solutionOn) {
                return;
            }
            form?.setFieldValue(id, input?.value);
            setValue(input?.value || "");
            onChange(input?.value || "", id);
        });
        fetchCallback && fetchCallback();
    }

    const fetchData = () => {
        loadValues();
        return InputValue.listen(id, player?.getId(), (value) => {
            if (context.solution?.solutionOn) {
                return;
            }
            form?.setFieldValue(id, value);
            setValue(value || "");
            onChange(value || "", id);
        });
    }

    useEffect(() => {
        if (context?.session?.scenario && scenarios[context.session.scenario].solo) return;
        if (textSize || customSize) {
            setValue(form?.getFieldValue(id) || "");
        }
        const channel = fetchData()
        SyncService.register(id, fetchData);

        return () => {
            channel?.unsubscribe();
        }
    }, [form, id, player?.getId(), textSize, customSize]);

    const onChangeInput = (e : any) => {
        if (!e.target) {
            return;
        }
        
        if (context.solution?.solutionOn) {
            return;
        }
        
        if (!(context?.session?.scenario && scenarios[context.session.scenario].solo)) {
            const value = e.target.value;
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
    
            debounceTimeoutRef.current = setTimeout(async () => {
                if (e.target) {
                    await InputValue.updateOrCreate(id, e.target.value, player?.getId())
                }
            }, 1000);
            onChange(value, id);
        } else {
            if (!context.solution?.solutionOn) {
                const value = e.target.value;
                onChange(value, id);
            }
        }
    }

    if (!playersNumbers && number) {
        playersNumbers = [number];
    }
    const playerToAnswer = playersNumbers ? playersTeam.find(p => p.number && playersNumbers?.includes(p.number)) : null;
    const playerDisabled = !!(playerToAnswer && playerToAnswer.number !== player?.number);
    if (!forcePlacholder) {
        placeHolder = playerDisabled ? t.sessionGame.game.inputOtherPlayer : placeHolder;
    }

    if (textSize || customSize) {
        const updateValue = (e: ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
            form?.setFieldValue(id, e.target.value);
            onChangeInput(e);
        }
        return (
            <>
                <Input
                    name={id}
                    value={value}
                    disabled={playerDisabled || isDisabled || context.solution?.solutionOn}
                    onChange={updateValue}
                    className={classNameMemo}
                    addonAfter={addonAfter}
                    placeholder={placeHolder}
                    size={size}
                    style={{
                        borderColor: color?? undefined,
                        borderWidth: color ? "3px" : undefined,
                        padding: noPadding ? "0" : undefined,
                        height: `${customSize ?? undefined}em`,
                        fontSize: `${textSize ?? 1}em`
                    }}
                    maxLength={maxLength}
                />
                <Form form={form} preserve={false} style={{height: 0}}>
                    <Form.Item name={id} className={"m-0"}>
                        <Form.Item noStyle shouldUpdate>
                            {() => {
                                context.solution?.solutionOn && setValue(form?.getFieldValue(id) || "");
                                onChange(form?.getFieldValue(id) || "", id);
                                return null;
                            }}
                        </Form.Item>
                    </Form.Item>
                </Form>
            </>
        )
    }

    return (
            <Form form={form} preserve={false}>
                <Form.Item className={"m-0"} name={id}>
                    <Input size={size} disabled={playerDisabled || isDisabled || context.solution?.solutionOn} onChange={onChangeInput} className={classNameMemo} addonAfter={addonAfter} placeholder={placeHolder}
                        style={{borderColor: color?? undefined, borderWidth: color ? "3px" : undefined, padding: noPadding ? "0" : undefined}} maxLength={maxLength}
                    />
                </Form.Item>
            </Form>
    )

}