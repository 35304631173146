import {Button, Flex, Modal} from "antd";
import React, {Dispatch, SetStateAction} from "react";
import {TrophyOutlined, WarningOutlined} from "@ant-design/icons";
import ConfettiExplosion from 'react-confetti-explosion';
import {Team} from "../../models/Team/Team";
import {Player} from "../../models/Player/Player";
import WaitingForPlayers from "./WaitingForPlayers";
import {supabase} from "../../../configs/supabase";
import {UserData} from "../../models/UserData/UserData";
import t from "../../../configs/i18n";
import { useGameContext } from "../../../pages/session_game/context/context.game";
import { Role } from "../../models/UserData/Role";
import scenarios from "../../../scenarios/scenarios";
import Notification from "../../services/notification/Notification";


interface PopupGoodAnswerProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>;
    team: Team | undefined;
    player: Player | undefined;
    playersTeam: Player[];
    currentUser: UserData | undefined;
    customText?: string;
    closable?: boolean;
    noConfetti?: boolean;
    yesButton?: string;
    noButton?: string;
}

export default function PopupGoodAnswer({open, setOpen, team, player, playersTeam, currentUser, customText, closable, noConfetti, yesButton, noButton} : PopupGoodAnswerProps) {
    const [waitingPlayerModal, setWaitingPlayerModal] = React.useState<boolean>(false);
    const context = useGameContext();

    const nextStep = () => {
        setOpen(false)

        if (context.session?.scenario && (scenarios[context.session.scenario].solo || scenarios[context.session.scenario].tablet)) {
            nextStepSolo()
            return
        }

        nextStepDefault()
    }

    const nextStepDefault = async () => {
        if (currentUser?.hasRights(Role.GAMEMASTER, context?.session?.id)) {
            setWaitingPlayerModal(true);
            return;
        }

        if (player) {
            await supabase
                .from('player')
                .update({step_validated: true})
                .eq('id', player.playerId)
            setWaitingPlayerModal(true);
        }
    }

    const nextStepSolo = async () => {
        if (player && context.team?.step) {
            const response = await supabase
                .rpc("update_team", {
                    team_id: team?.id,
                    new_step_number: context.team.step + 1,
                });
            
            if (response.error) {
                Notification.error("Erreur lors de la modification de l'équipe.", response.error);
                return;
            }
        }
        
    }

    return (
        <>
            <WaitingForPlayers setOpen={setWaitingPlayerModal}  currentUser={currentUser} open={waitingPlayerModal} team={team} playersTeam={playersTeam} stepNumber={team?.step} />
            <Modal closeIcon={closable ?? true} open={open} footer={null} onCancel={() => setOpen(false)} width={600}>
                <Flex align={"center"} justify={"center"} vertical>
                    {open && !noConfetti && <ConfettiExplosion width={2000} zIndex={9999999} />}
                    {noConfetti ? <WarningOutlined className={"text-4xl text-green-light"}/> : <TrophyOutlined  className={"text-4xl text-green-light"}/>}
                    <h3 className={"mt-20 mb-20 text-center w-[90%]"}>{customText ?? t.sessionGame.goodAnswerPopup.title}</h3>
                    <Flex justify={"center"} className={"space-x-[1vw] w-full"}>
                        <Button onClick={nextStep} type={"primary"}>{yesButton ?? t.sessionGame.goodAnswerPopup.button}</Button>
                        {noButton && <Button
                            onClick={() => setOpen(false)}
                            className="bg-red-600 hover:bg-red-700"
                            style={{ color: 'white', border: '0px'}}
                        >
                            {noButton}
                        </Button>}
                    </Flex>
                </Flex>
            </Modal>
        </>
    )
}