

export default function modalSize(size: {w: number, h: number, max_w?: number} | undefined): {w: string, h: string, fontSize: string} | undefined {
    if (!size) {
        return undefined;
    }
    const modalSize = {w: size.w, h: size.h, max_w: size.max_w ?? 70};
    const expectedWidthOverScreen = window.innerHeight * modalSize.w > modalSize.max_w * window.innerWidth;
    const width = expectedWidthOverScreen ? modalSize.max_w : size.w;
    const height = expectedWidthOverScreen ? modalSize.h * width / modalSize.w : size.h;
    const unit = expectedWidthOverScreen ? "vw" : "vh";
    const fontSize = 16 * (expectedWidthOverScreen ? (width * window.innerWidth) / (1080 * modalSize.w) : window.innerHeight / 1080);
    return {w: `${width}${unit}`, h: `${height}${unit}`, fontSize: `${fontSize}px`};
}