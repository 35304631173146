import sustainableItFrScenario from "./sustainable-it/sustainable-it.fr.scenario";
import React, {ReactElement} from "react";
import {BaseHintProps} from "./types/BaseHintProps";
import sustainableItEnScenario from "./sustainable-it/sustainable-it.en.scenario";
import {ecoMobilityEnScenario} from "./ecomobility/ecomobility.en.scenario";
import {sustainableItTabletFr} from "./sustainable-it-tablet/sustainable-it-tablet.fr";
import {ecoMobilityFrScenario} from "./ecomobility/ecomobility.fr.scenario";
import {ecoMobilityDassaultFrScenario} from "./ecomobility/ecomobility.fr.dassault.scenario";
import sustainableItFrDecathlonScenario from "./sustainable-it/sustainable-it.fr.decathlon.scenario";
import sustainableItEnDecathlonScenario from "./sustainable-it/sustainable-it.en.decathlon.scenario";
import sustainableItFrSolo from "./sustainable-it-solo/sustainable-it.solo.fr";
import sustainableItFrEdfScenario from "./sustainable-it/sustainable-it.fr.edf.scenario";
import { StepTemplateParameters } from "./generic/stepTemplates/BasicStepTemplate";
import dgseEnScenario from "./generic/dgse.en.scenario";
import { PasswordModalParameters, ModalParameters } from "../shared/models/Templates";
import { AdaptationType } from "../shared/models/Player/PlayerAdaptation";
import dgseFrScenario from "./generic/dgse.fr.scenario";
type Step = {
    description?: any
    passwordModal?: PasswordModalParameters;
    modals?: { [key: string]: ModalParameters };
    room?: StepTemplateParameters;
    component: ReactElement;
    baseHints: ((props : BaseHintProps) => ReactElement)[];
    adaptations?: AdaptationType[];
}
type Scenario = {
    title: string;
    description: string;
    longDescription: string;
    rules: string | string[];
    notLearningType?: boolean; // For the survey
    lang: string;
    tablet: boolean;
    solo?: boolean | undefined;
    finalTeamQuizz?: boolean;
    finalTeamQuizzStep?: number;
    steps: Step[];
    newFormat?: boolean; // TODO : remove this when all scenarios have the new format
};

type Scenarios = {
    [key: string]: Scenario;
};

const scenarios : Scenarios = {
    "sustainable.fr.it": sustainableItFrScenario,
    "sustainable.en.it": sustainableItEnScenario,
    "sustainable.fr.decathlon.it": sustainableItFrDecathlonScenario,
    "sustainable.en.decathlon.it": sustainableItEnDecathlonScenario,
    "sustainable.fr.edf.it": sustainableItFrEdfScenario,
    "ecomobility.en.it": ecoMobilityEnScenario,
    "ecomobility.fr.it": ecoMobilityFrScenario,
    "ecomobility.fr.dassault.it": ecoMobilityDassaultFrScenario,
    "sustainable.tablet.fr.it": sustainableItTabletFr,
    "dgse.en": dgseEnScenario,
    "dgse.fr": dgseFrScenario,
    "sustainable.solo.fr.it": sustainableItFrSolo
}

export default scenarios;

export type { Scenario, Step };
