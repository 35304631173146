import { Flex, Form, FormInstance } from "antd";
import SyncronizedInput from "../../../shared/components/input/SyncronizedInput";
import { useGameContext } from "../../../pages/session_game/context/context.game";
import Normalize from "../../../shared/services/formatter/normalize";
import { SimpleInput } from "../../../shared/models/Templates";
import StyleService from "../../../shared/services/templates/StyleService";

export default function Code1number(
    props: {
        inputs: SimpleInput[];
        className?: string;
        form: FormInstance;
        onChange?: (value: string, id: string) => void;
    }
) {
    const context = useGameContext();
    const { form } = props;

    const watchedValues = Form.useWatch((values) => values, form);
    const getClassName = (input: SimpleInput) => StyleService.getInputColorClassName(input, watchedValues && Normalize.answer(watchedValues[`${context.team?.id}-${context.team?.step}-${input.label}`]));

    return (
        <Flex className={`space-x-1 ${props.className}`}>
            {props.inputs.map((input, index) => {
                return <div style={{width: `${input.height ?? 6}em`}}>
                    <SyncronizedInput
                        className={`text-center border-[1px] ${getClassName(input)}`}
                        id={`${context.team?.id}-${context.team?.step}-${input.label}`}
                        player={context.player}
                        playersTeam={context.playersTeam}
                        number={input.player && !Array.isArray(input.player) ? input.player : undefined}
                        playersNumbers={input.player && Array.isArray(input.player) ? input.player : undefined}
                        placeHolder="?"
                        form={form}
                        color={input.color}
                        forcePlacholder
                        noPadding
                        maxLength={1}
                        onChange={props.onChange}
                        textSize={input.textSize ?? 3.5}
                    />
                </div>
            })}
        </Flex>
    );
}