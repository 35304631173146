import { Button, Flex } from "antd";
import { BasicModalContent } from "./BasicModalTemplate";
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { useGameContext } from "../../../pages/session_game/context/context.game";
import { ModalParameters } from "../../../shared/models/Templates";
import Normalize from "../../../shared/services/formatter/normalize";
import TypeService from "../../../shared/services/TypeService";
import modalSize from "../../../shared/services/utils/modalSize";
import AppSpinner from "../../../shared/components/spinner/AppSpinner";

export default function MissingHintTemplate({number, setWidth}: {number: number, setWidth?: (width: number | string) => void}) {
    const context = useGameContext();
    const [current, setCurrent] = useState(0);

    const hints: ModalParameters[] = useMemo(() => {
        const modalsMap = Object.entries(context.step?.modals ?? {})
            .filter(([_, modal]) => modal && (!modal.players || modal.players?.includes(number)) && !modal.dummy)
            .filter(([_, modal]) => context.player?.hasAdaptation(modal.adaptation))
            .reduce((acc, [key, modal]) => {
                acc[Normalize.modalKey(key)] = modal;
                return acc;
            }, {} as {[key: string]: ModalParameters});
        return context.step?.room?.buttons
            .filter(button => button.action.type === "modal" && !button.dummy && (!button.players || button.players?.includes(number)))
            .map(button => modalsMap?.[button.action.target ?? ""])
            .filter(hint => hint !== undefined) as ModalParameters[]
    }, [context.step?.room?.buttons, context.step?.modals, number]);

    const type: string | undefined = useMemo(() => TypeService.modalDescriptionType(hints[current]?.descriptions), [hints && hints[current]?.descriptions]);
    const [size, setSize] = useState<{w: string, h: string, fontSize: string} | undefined>(undefined);

    const prev = () => {
        setCurrent(current - 1 < 0 ? hints.length - 1 : current - 1);
    }

    const next = () => {
        setCurrent(current + 1 < hints.length ? current + 1 : 0);
    }

    useEffect(() => {
        setCurrent(0);
    }, [context.step]);

    useEffect(() => {
        const handleResize = () => {
            const size = hints[current]?.size;
            if (!size) return;
            setSize(modalSize({w: size.w + (hints.length === 1 ? 0 : 20), h: size.h, max_w: size.max_w ?? 70 + (hints.length === 1 ? 0 : 20)}));
        }

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [hints[current]?.size, current]);

    useEffect(() => {
        if (!size?.w) return;
        setWidth?.(size.w);
    }, [size?.w]);

    if (hints.length === 0) {
        return <></>;
    }

    if (hints.length === 1) {
        return <Flex justify={"center"} align={"center"} style={{height: size?.h}}>
            <BasicModalContent type={type} modal={hints[0]} fontSize={size?.fontSize}/>
        </Flex>;
    }
    
    if (!size?.h || !size?.w) {
        return <AppSpinner size={"large"}/>;
    }

    return (
        <Flex justify={"center"} align={"center"}>
            <Button onClick={prev} type={"primary"} className="text-center align-center items-center justify-center flex" style={{fontSize: size?.fontSize, width: "5vh", height: "4vh"}}><LeftCircleFilled/></Button>
            <Flex style={{width: size?.w, height: size?.h}}>
                <BasicModalContent type={type} modal={hints[current]} fontSize={size?.fontSize}/>
            </Flex>
            <Button onClick={next} type={"primary"} className="text-center align-center items-center justify-center flex" style={{fontSize: size?.fontSize, width: "5vh", height: "4vh"}}><RightCircleFilled/></Button>
        </Flex>
    )
}
