import { useEffect, useMemo, useState } from "react";
import { useGameContext } from "../../../../pages/session_game/context/context.game";
import MissingHintTemplate from "../../modalTemplates/MissingHintTemplate";
import { ClickableObjectParameters } from "../../stepTemplates/components/ClickableObject";
import AppSpinner from "../../../../shared/components/spinner/AppSpinner";

export default function MissingPlayers11({number, setWidth}: {number: number, setWidth?: (width: number | string) => void}) {
    const context = useGameContext();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        if (!context.step || !context.step.room) return;

        context.step.modals = {
            ...context.step.modals,
            ...Object.fromEntries([2, 4, 6, 8, 10].map(stepIndex => ([
                [`sticker${stepIndex}-${number}`], {
                    key: `sticker${stepIndex}-${number}`,
                    players: [number],
                    size: {w: 40, h: 40},
                    descriptions: [
                        {
                            type: "image",
                            content: `https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/en/step${stepIndex}/hint_p${number}.webp`,
                            position: {x: 50, y: 50, type: "center"},
                            size: 95
                        }
                    ]
                }
            ])))
        };

        context.step.room.buttons = [...context.step.room.buttons, ...[2, 4, 6, 8, 10].map(stepIndex => ({
            key: `sticker${stepIndex}-${number}`,
            type: "invisible",
            position: {x: 52.2, y: 69},
            action: {
                type: "modal",
                target: `sticker${stepIndex}-${number}`
            },
        } as ClickableObjectParameters))];

        setLoading(false);

    }, [number]);

    if (loading) return <AppSpinner />;

    return <MissingHintTemplate number={number} setWidth={setWidth}/>
}