import React from 'react';

import {Flex, Image} from "antd";
import Session from "../../../shared/models/Session/session";
import {Company} from "../../../shared/models/Company/company";

interface BannerProps {
    session: Session | undefined
    company: Company | undefined | null
}

function Banner({session, company} : BannerProps) {
    return (
        <div className="w-full bg-beige pt-1 pb-1 box-border">
            <Flex className={"h-full items-center ml-2"}>
                <Image src={company?.logo} className="ml-4 mr-6 max-h-[40px] max-w-[60px] object-scale-down" placeholder={false} preview={false}/>
                <Flex className="text-xs text-green-light" vertical>
                    <p className="font-bold m-0">{session?.name}</p>
                    <p className="m-0">{company?.name}</p>
                </Flex>
            </Flex>
        </div>
    )
}

export default Banner;
