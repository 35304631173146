import { Flex } from 'antd';
import { useState, useEffect, useRef } from 'react';

export default function ImageRatioBackground(
    props: {
        bgImage: string;
        children?: React.ReactNode;
        modal?: boolean;
        style?: React.CSSProperties;
    }
) {
  const containerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 0, x: 0, y: 0});

  const handleResize = () => {
    if (!containerRef.current) return;
    const img = new Image();
    const bgImage = getComputedStyle(containerRef.current).backgroundImage.slice(5, -2);
    img.src = bgImage;
    
    img.onload = () => {
      if (!containerRef.current) return;
      const { offsetWidth, offsetHeight } = containerRef.current;
      const aspect = img.width / img.height;
      const tooWide = offsetWidth > offsetHeight * aspect;
      
      setSize(tooWide ? { width: offsetHeight * aspect, height: offsetHeight, x: (offsetWidth - offsetHeight * aspect) / 2, y: 0} : { width: offsetWidth, height: offsetWidth / aspect, x: 0, y: (offsetHeight - offsetWidth / aspect) / 2});
    };
  };

  useEffect(() => {
    handleResize();
  }, [size]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!props.bgImage) {
    return <Flex className="w-full h-full bg-beige">{props.children}</Flex>;
  }

  return (
    <Flex className={`h-full w-full ${props.modal ? "bg-transparent" : "bg-beige"}`} style={props.style}>
      <Flex
        ref={containerRef}
        className="w-full h-full mx-[0.3%]"
        style={{
          backgroundImage: `url(${props.bgImage})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div style={{ width: size.width, height: size.height, position: 'relative', left: size.x, top: size.y }}>
          {props.children}
        </div>
      </Flex>
    </Flex>
  );
}