import {Button, Flex, Input, Modal} from "antd";
import React, {useMemo, useState} from "react";
import {DeleteOutlined, PlusOutlined, UserOutlined} from "@ant-design/icons";
import scenarios from "../../../../../../../scenarios/scenarios";
import {supabase} from "../../../../../../../configs/supabase";
import Session from "../../../../../../../shared/models/Session/session";
import { newPlayer, newPlayersTablet } from "../../../../../../../shared/models/Player/PlayerService";
import { Company } from "../../../../../../../shared/models/Company/company";
import t from "../../../../../../../configs/i18n";


interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    session: Session | undefined
}

export default function ManualPopupInfoSessionHome(props: Props) {
    const scenario = useMemo(() => scenarios[props.session?.scenario as string], [props.session?.scenario])
    const emptyPlayer = {mail: "", firstName: "", lastName: "", company: ""};

    const [playerToCreate, setPlayerToCreate] = useState<{
        mail: string,
        firstName: string,
        lastName: string,
        company: string
    }[]>([emptyPlayer]);


    const handleDelete = (index: number) => {
        setPlayerToCreate(currentPlayers => {
            const newPlayers = [...currentPlayers];
            newPlayers.splice(index, 1);
            return newPlayers;
        });
    }

    const createPlayer = async () => {
        if (!props.session) return;
        
        if (scenario?.tablet) {
            await newPlayersTablet(props.session, playerToCreate)
        } else {
            for (const player of playerToCreate) {
                const companyQuery = await supabase.from("company").select("*").eq("name", player.company).maybeSingle()
                const company = companyQuery.data ? Company.newCompany(companyQuery.data) : props.session?.company;
                await newPlayer(props.session, company?.id, player.mail, player.firstName, player.lastName, "")
            }
        }

        setPlayerToCreate([])
        props.setOpen(false);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, param: string) => {
        setPlayerToCreate(currentPlayers => {
            const newPlayers = [...currentPlayers];
            switch (param) {
                case "firstName":
                    newPlayers[index].firstName = e.target.value;
                    break;
                case "lastName":
                    newPlayers[index].lastName = e.target.value;
                    break;
                case "mail":
                    newPlayers[index].mail = e.target.value;
                    break;
                case "company":
                    newPlayers[index].company = e.target.value;
                    break;
                default:
                    break;
            }
            return newPlayers;
        });
    }

    return (
        <Modal destroyOnClose={true} className={"min-w-[800px]"} open={props.open} onCancel={() => props.setOpen(false)} footer={false} >
            <Flex gap={"20px"} className={"w-full pb-8 "} vertical={true}>
                <h2>Ajouter manuellement un participant</h2>
                <Flex gap={"10px"} className={"max-h-[250px] overflow-y-auto"} vertical>
                    {playerToCreate.map((player, index) => (
                        <Flex gap={"30px"}>
                            <UserOutlined/>
                            <Input className={"min-w-[230px]"} onChange={e => onChange(e, index, "mail")} placeholder={t.admin.newParticipant.email} value={player.mail}/>
                            <Input placeholder={t.admin.newParticipant.firstName} onChange={(e) => onChange(e, index, "firstName")} value={player.firstName}/>
                            <Input placeholder={t.admin.newParticipant.lastName} onChange={(e) => onChange(e, index, "lastName")} value={player.lastName}/>
                            {!scenario?.tablet && <Input placeholder={t.admin.newParticipant.company} onChange={(e) => onChange(e, index, "company")} value={player.company}/>}
                            <Button danger onClick={() => handleDelete(index)}><DeleteOutlined/></Button>
                        </Flex>
                    ))}
                </Flex>
                <Button onClick={() => setPlayerToCreate([...playerToCreate, emptyPlayer])}>
                    <PlusOutlined/> Ajouter un participant
                </Button>
                <Button onClick={createPlayer} type={"primary"}>
                    Valider
                </Button>
            </Flex>
        </Modal>

    )
}