import {useEffect, useMemo, useState} from "react";
import {Flex, notification} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {Player} from "../../models/Player/Player";
import {Team} from "../../models/Team/Team";
import PlayerInfoPopup from "./PlayerInfoPopup";
import {supabase} from "../../../configs/supabase";
import Notification from "../../services/notification/Notification";

interface PlayerBoxProps {
    player?: Player | undefined;
    team?: Team | undefined;
    number?: number;
    teams?: Team[] | undefined
    popup?: boolean;
    online?: boolean;
    name?: string;
}

function PlayerBox({player, teams, team, number, popup = true, online = true, name}: PlayerBoxProps) {
    const popupStyle = popup ? "cursor-pointer" : "";
    const [open, setOpen] = useState<boolean>(false);
    const [bgColor, setBgColor] = useState<string>("bg-gray-400");
    const borderColor = useMemo(() => {
        if (player?.adaptation?.includes("daltonien")) {
            return "border-[#253570] border-4 border-solid";
        }
        return "";
    }, [player?.adaptation?.length]);

    useEffect(() => {
        let titleInterval : NodeJS.Timer;
        if (online && player && !player.team && player.need_gamemaster) {
            let blink = false;
            titleInterval = setInterval(() => {
                setBgColor(blink ? "bg-red-500" : "bg-green-light");
                blink = !blink;
            }, 500); // Change the interval as needed
        }
        setBgColor(online ? "bg-green-light" : "bg-gray-400")
        return () => {
            if (titleInterval) {
                clearInterval(titleInterval);
            }
        };
    }, [online, player]);

    const handleDragStart = (e: any) => {
        if (!player) {
            return;
        }
        if (player?.team && player?.need_gamemaster) {
            return;
        }

        e.dataTransfer?.setData("text/plain", player?.playerId || "");
    };

    const getName = (i?: number | undefined) => {
        const renderedName = name ?? player?.getName();
        if (i === undefined) {
            return renderedName;
        }
        if (!renderedName || renderedName === " ") {
            console.warn("Name is undefined");
            return i === 0 ? player?.getEmail() : "";
        }
        const nameArr: string[] | undefined = (renderedName)?.split(" ");
        if (!nameArr || i + 1 > nameArr.length) {
            console.warn("Name is too short");
            return "";
        }

        return nameArr[i];
    }

    const handleDragOver = (e: any) => {
        e.preventDefault(); // Nécessaire pour permettre le drop
    };

    const handleDrop = async (e: any) => {
        if (!team || !number) {
            return;
        }

        const playerId = e.dataTransfer.getData("text/plain");
        if (!playerId) {
            notification.warning({message: "Résoudre le problème du joueur avant de le déplacer"});
            return;
        }
        e.preventDefault();
        const updatePlayer = await supabase
            .rpc("update_player", {
                player_id: playerId,
                team_uuid: team?.id,
                player_number: number,
                new_name: null
            });

        if (updatePlayer.error) {
            Notification.error("Erreur lors de l'update d'un joueur", updatePlayer.error);
            return;
        }

        if (updatePlayer.data !== null) {
            Notification.error(`Conflit avec le joueur ${updatePlayer.data}`);
            return;
        }

        notification.success({message: "Joueur mis à jour"});
    };

    if (!player && !name) {
        return (
            <Flex onDragOver={handleDragOver} onDrop={handleDrop} align={"center"} className={"border-2 border-gray-400 border-dashed rounded-lg text-gray-400 m-2 w-[8.77rem] h-10 text-xs"}>
                <p className={"m-0 ml-2 mt-2 mb-2 leading-none font-light text-center justify-center flex w-full"}>Aucun joueur</p>
            </Flex>
        )
    }


    return (
        <Flex>
            <PlayerInfoPopup player={player} teams={teams} open={open} setOpen={setOpen} />
            <Flex
                draggable={true}
                onDragStart={handleDragStart}
                onClick={() => setOpen(popup)}
                align={"center"}
                className={`align-center ${bgColor} rounded-lg font-bold text-white m-2 w-[8.77rem] h-10 pr-2 ${popupStyle} hover:bg-green-dark transition-all ease-in text-xs ${borderColor}`}
            >
                <UserOutlined className={"m-0 ml-1"}/>
                <p className={"m-1 leading-none font-light break-all text-center justify-center flex w-full"}>{getName(0)}<br/>{getName(1)}</p>
                <p></p>
            </Flex>
        </Flex>
    )
}

export default PlayerBox;
