import {Form, FormInstance, Select} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {InputValue} from "../../models/Team/InputValue";
import {Player} from "../../models/Player/Player";
import SyncService from "../../services/sync/SyncService";
import t from "../../../configs/i18n";
import "./styles/select.css";

interface SyncronizedInputProps {
    id : string;
    player: Player | undefined;
    playersTeam: Player[];
    options: {label: string, value: string}[];
    form?: FormInstance;
    number?: number;
    playersNumbers?: number[];
    className?: string;
    placeHolder?: string;
    getValue?: (value: string) => void;
    size?: "large" | "middle" | "small";
    onChange?: (value: string, id: string) => void;
    isDisabled?: boolean;
    width?: number | string;
    height?: number | string;
    popupMatchSelectWidth?: number | boolean;
    noForm?: boolean;
    textSize?: number;
}



export default function SyncronizedSelect({id, player, options, playersTeam, number, playersNumbers, form, className = "", placeHolder = "", size = "middle", onChange = () => {}, isDisabled = false, width = 200, height = "auto", popupMatchSelectWidth, noForm = false, textSize} : SyncronizedInputProps) {
    const [value, setValue] = useState(form?.getFieldValue(id) || "");

    const fetchData = () => {
        InputValue.byId(id, false).then(input => {
            if (!input) {
                return;
            }
            form?.setFieldValue(id, input?.value);
            setValue(input?.value || "");
            onChange(input?.value || "", id);
        });

        return InputValue.listen(id, player?.getId(), (value) => {
            form?.setFieldValue(id, value);
            setValue(value || "");
            onChange(value || "", id);
        });
    }

    useEffect(() => {
        const channel = fetchData()
        SyncService.register(id, fetchData);

        return () => {
            channel?.unsubscribe();
        }
    }, [form, id, player?.getId()]);

    const onChangeInput = async (value : any) => {
        if (value === undefined) {
            return;
        }

        onChange(value, id);
        await InputValue.updateOrCreate(id, value, player?.getId())
    }
    if (!playersNumbers && number) {
        playersNumbers = [number];
    }
    const playerToAnswer = playersNumbers ? playersTeam.find(p => p.number && playersNumbers?.includes(p.number)) : null;
    const playerDisabled = !!(player?.number !== number && playerToAnswer);
    placeHolder = playerDisabled ? t.sessionGame.game.inputOtherPlayer : placeHolder;

    const [dropdownStyle, setDropdownStyle] = useState<React.CSSProperties | undefined>(undefined);

    const updateDropdownStyle = () => {
        const select = document.querySelector('.reponsive-select .ant-select');
        if (!select) {
            return;
        }
        console.log('debug', window.getComputedStyle(select as Element).fontSize);
        setDropdownStyle({fontSize: window.getComputedStyle(select as Element).fontSize});
    }
    useEffect(() =>{
        if (!noForm) {return;}
        updateDropdownStyle();

        window.addEventListener('resize', updateDropdownStyle);
        return () => {
            window.removeEventListener('resize', updateDropdownStyle);
        }
    }, [form, id, textSize]);

    if (noForm) {
        const onChangeValue = (value: string) => {
            setValue(value);
            form?.setFieldValue(id, value);
            onChangeInput(value);
        }
        return (
            <>
                <Select
                    dropdownRender={(menu) => <div className={'dynamic-em-dropdown-synchronized-select'} style={dropdownStyle}>{menu}</div>}
                    style={{width: width, height: height, fontSize: `${textSize}em`}}
                    options={options}
                    placement="bottomLeft"
                    size={size}
                    disabled={playerDisabled || isDisabled}
                    value={value}
                    onChange={onChangeValue}
                    className={className}
                    placeholder={placeHolder}
                />
                <Form form={form} preserve={false} style={{height: 0}}>
                    <Form.Item name={id} className={"m-0"}>
                        <Form.Item noStyle shouldUpdate>
                            {() => {
                                if (value === form?.getFieldValue(id)) {
                                    return null;
                                }
                                setValue(form?.getFieldValue(id) || "");
                                onChange(form?.getFieldValue(id) || "", id);
                                return null;
                            }}
                        </Form.Item>
                    </Form.Item>
                </Form>
            </>
        );
    }
        return (
            <Form form={form} preserve={false}>
                <Form.Item className={"m-0"} name={id}>
                    <Select
                        style={{width: width, height: height}}
                        options={options}
                        popupMatchSelectWidth={popupMatchSelectWidth}
                        placement="bottomLeft"
                        size={size}
                        disabled={playerDisabled || isDisabled}
                        onChange={onChangeInput}
                        className={className}
                        placeholder={placeHolder}
                    />
                </Form.Item>
            </Form>
    )

}