import Session from "../../../shared/models/Session/session";
import {Button, Card, Checkbox, Flex, Input, Modal, Upload, notification} from "antd";
import {
    PlayCircleOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    StopOutlined,
    UploadOutlined,
    UserAddOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";
import React, {useMemo, useState} from "react";
import {SessionState} from "../../../shared/models/Session/sessionState";
import {supabase} from "../../../configs/supabase";
import Notification from "../../../shared/services/notification/Notification";
import Popup from "../../../shared/components/popup/Popup";
import {UserData} from "../../../shared/models/UserData/UserData";
import scenarios from "../../../scenarios/scenarios";
import { uploadSessionImage } from "../../../shared/services/images/upload";
import t from "../../../configs/i18n";
import { Team } from "../../../shared/models/Team/Team";

interface SessionStateCardProps {
    session: Session | undefined
    user: UserData | undefined;
    teams: Team[] | undefined;
}

export default function SessionStateCard({ session, user, teams }: SessionStateCardProps) {
    const scenario = useMemo(() => scenarios[session?.scenario || ""], [session?.scenario]);
    const isMaxVideo = useMemo(() => session?.isMaxVideo(), [session?.subState, scenario]);
    const [externalVisioModal, setExternalVisioModal] = useState<boolean>(false);


    const hasVideo = useMemo(() => scenario?.rules?.length !== 0 && scenario?.rules !== "", [scenario?.rules]);
    const openSession = () => {
        supabase
            .from("session")
            .update({state: SessionState.OPEN})
            .eq("id", session?.id)
            .then(() => {
                notification.success({message: "Session ouverte",});
            });
    }

    const startSession = () => {
        supabase
            .from("session")
            .update({state: SessionState.RULES})
            .eq("id", session?.id)
            .then(() => {
                notification.success({message: "Présentation lancée",});
            });
    }

    const startVideo = () => {
        supabase
            .from("session")
            .update({state: SessionState.VIDEO, sub_state: 0})
            .eq("id", session?.id)
            .then(() => {
                notification.success({message: "Présentation lancée"});
            });
    }

    const nextVideo = () => {
        supabase
            .from("session")
            .update({sub_state: (session?.subState ?? 0) + 1})
            .eq("id", session?.id)
            .then(() => {
                notification.success({message: "Vidéo suivante"});
            });
    }

    const startGame = () => {
        supabase.rpc("start_session", {session_id: session?.id}).then((payload) => {
            if (payload.error) {
                Notification.error('Erreur lors du lancement de la session.', payload.error);
                return;
            }

            notification.success({message: "Session lancée"});
        })
    }

    const reStartSession = () => {
        supabase
            .from("session")
            .update({state: SessionState.OPEN})
            .eq("id", session?.id)
            .then(() => {
                notification.success({message: "Session redémarée",});
            });
    }

    const stopSession = () => {
        supabase
            .from("session")
            .update({state: SessionState.FINAL})
            .eq("id", session?.id)
            .then(() => {
                notification.success({message: "Session arrêtée",});
            });
    }

    const closeSession = () => {
        supabase
            .from("session")
            .update({state: SessionState.CLOSED})
            .eq("id", session?.id)
            .then(() => {
                notification.success({message: "Session fermée",});
            });
    }

    const survey = () => {
        supabase
            .from("session")
            .update({state: SessionState.SURVEY})
            .eq("id", session?.id)
            .then(() => {
                notification.success({message: "Questionnaire de satisfaction lancé",});
            });
    }

    const showRanking = async () => {
        const {data, error} = await supabase.from("team").select("id").eq("session", session?.id)

        if (error) {
            Notification.error("Erreur lors de l'affichage du classement", error);
            return;
        }

        const teamCount = data?.length || 0;
        const rank = teamCount >= 3 ? SessionState.RANK_3 : teamCount === 2 ? SessionState.RANK_2 : SessionState.RANK_1;

        supabase
            .from("session")
            .update({state: rank})
            .eq("id", session?.id)
            .then(() => {
                notification.success({message: "Classement affiché",});
            });
    }

    const showTop2 = () => {
        supabase
            .from("session")
            .update({state: SessionState.RANK_2})
            .eq("id", session?.id)
            .then(() => {
                notification.success({message: "Top 2 affiché",});
            });
    }

    const showTop1 = () => {
        supabase
            .from("session")
            .update({state: SessionState.RANK_1})
            .eq("id", session?.id)
            .then(() => {
                notification.success({message: "Top 1 affiché",});
            });
    }

    const endSession = () => {
        supabase
            .from("session")
            .update({state: SessionState.ENDED})
            .eq("id", session?.id)
            .then(() => {
                notification.success({message: "Session Terminée",});
            });
    }

    const playersTeam = async () => {
        const response = await supabase
            .rpc("auto_team", {
                session_id: session?.id,
                template: t.bdd.teamName,
            })

        if (response.error) {
            Notification.error("Erreur lors de la répartition des joueurs.", response.error);
            return;
        }

        // TODO : Use supabase function when proper BDD without JSON
        if (!session || session.getNumberGamemasters() === 0) return;

        // Get the number of teams
        const responseTeam = await supabase
            .from("team")
            .select("id")
            .eq("session", session?.id)
            .is("gamemaster", null);

        if (responseTeam.error) {
            Notification.error("Erreur lors de la répartition des joueurs.", responseTeam.error);
            return;
        }

        const nbTeams = responseTeam.data?.length || 0;
        const teamsPerGamemaster = (nbTeams / session?.getNumberGamemasters()) || 1;
        const gamemasters = session.gamemasters;

        // Assign the teams to the gamemasters
        const assign = async (team: any, i: number) => {
            if (i / teamsPerGamemaster >= gamemasters.length) return;
            const gamemaster = gamemasters[Math.floor(i / teamsPerGamemaster)].uid;

            const response = await supabase
                .from("team")
                .update({gamemaster: gamemaster})
                .eq("id", team.id);

            if (response.error) {
                Notification.error("Erreur lors de la répartition des joueurs.", response.error);
            }
        }
        responseTeam.data?.forEach((team, i) => {assign(team, i);});

        notification.success({message: "Joueurs répartis avec succès"});
    }

    const goToFinalQuizz = async () => {
        const responseSession = await supabase
            .from("session")
            .update({state: SessionState.QUIZZ})
            .eq("id", session?.id);

        if (responseSession.error) {
            Notification.error("Erreur", responseSession.error);
            return;
        }

        const response = await supabase
            .from("team")
            .update({
                step: scenario.finalTeamQuizzStep,
                sub_step: 0,
            })
            .eq("session", session?.id);

        if (response.error) {
            Notification.error("Erreur", response.error);
            return;
        }

        notification.success({message: "Succès"});
    }


    if (!session) {
        return <Card title={"Actions"} headStyle={{color: '#14665F', textAlign: "center"}}
              className="h-80 w-80 mt-8 ml-8 text-xs"/>;
    }

    return (
        <Card title={"Actions"} headStyle={{color: '#14665F', textAlign: "center"}}
              className="h-80 w-80 mt-8 ml-8 text-xs">
            <Flex vertical>
                {session?.state === SessionState.CLOSED &&
                    <Button disabled={!user?.isAdmin()} onClick={openSession}>
                        <PlayCircleOutlined /> Ouvrir la session
                    </Button>
                }
                {session?.state === SessionState.OPEN &&
                    <Flex vertical>
                        <Button disabled={!user?.isAdmin()} onClick={startSession} type={"primary"}>
                            <PlayCircleOutlined /> Démarrer la session
                        </Button>
                        <Button disabled={!user?.isAdmin()} className={"mt-4"} onClick={() => Popup.warning("Voulez vous fermer la session ?", closeSession)} type={"primary"} danger>
                            <StopOutlined /> Fermer la session
                        </Button>
                    </Flex>
                }

                {session?.state === SessionState.RULES && hasVideo &&
                    <Flex vertical>
                        <Button disabled={!user?.isAdmin()} onClick={startVideo} type={"primary"}>
                            <PlayCircleOutlined /> Démarrer la vidéo
                        </Button>
                    </Flex>
                }

                {
                    session?.state === SessionState.VIDEO && !isMaxVideo &&
                    <Flex vertical>
                        <Button disabled={!user?.isAdmin()} onClick={nextVideo} type={"primary"}>
                            <PlayCircleOutlined /> Prochaine vidéo
                        </Button>
                    </Flex>
                }

                {((session?.state === SessionState.VIDEO && isMaxVideo) || (session?.state === SessionState.RULES && !hasVideo)) &&
                    <Flex vertical>
                        <Button disabled={!user?.isAdmin()} onClick={startGame} type={"primary"}>
                            <PlayCircleOutlined /> Démarrer le jeu
                        </Button>
                    </Flex>
                }

                {Session.inGame(session?.state) &&
                    <Button disabled={!user?.isAdmin()} className={"mt-4"} onClick={() => Popup.warning("Voulez vous arrêter le jeu ?", stopSession)} type={"primary"} danger>
                        <StopOutlined /> Arrêter le jeu
                    </Button>
                }

                {session?.state === SessionState.FINAL &&
                    <Button disabled={!user?.isAdmin()} onClick={survey} type={"primary"}>
                        <PlayCircleOutlined /> Questionnaire de satisfaction
                    </Button>
                }

                {session?.state === SessionState.SURVEY &&
                    <Button disabled={!user?.isAdmin()} onClick={showRanking} type={"primary"}>
                        <PlayCircleOutlined /> Afficher le classement
                    </Button>
                }

                {session?.state === SessionState.RANK_3 &&
                    <Button disabled={!user?.isAdmin()} onClick={showTop2} type={"primary"}>
                        <PlayCircleOutlined /> Afficher le top 2
                    </Button>
                }

                {session?.state === SessionState.RANK_2 &&
                    <Button disabled={!user?.isAdmin()} onClick={showTop1} type={"primary"}>
                        <PlayCircleOutlined /> Afficher le top 1
                    </Button>
                }

                {session?.state === SessionState.RANK_1 &&
                    <Button disabled={!user?.isAdmin()} className={"mt-4"} onClick={endSession} type={"primary"} danger>
                        <StopOutlined /> Terminer la session
                    </Button>
                }

                {scenario?.finalTeamQuizz === true && session?.state === SessionState.IN_GAME &&
                    <Button disabled={!user?.isAdmin()} className={"mt-4"} onClick={goToFinalQuizz} type={"primary"}>
                        <QuestionCircleOutlined /> Allez au quizz final
                    </Button>
                }

                <Button disabled={!user?.isAdmin()} className={"mt-4"} onClick={() => Popup.warning("Voulez vous redémarrer la session ?", reStartSession)} danger>
                    <ReloadOutlined /> Redémarrer la session
                </Button>
                <ModalExternLinks open={externalVisioModal} setOpen={setExternalVisioModal} session={session} teams={teams} />
                <Button disabled={!user?.isAdmin()} className={"mt-4"} onClick={() => {setExternalVisioModal(true)}}>
                    <VideoCameraOutlined /> Options visio externe
                </Button>

                <Button disabled={!user?.isAdmin()} className={"mt-4"} onClick={() => Popup.warning("Voulez vraiment répartir les joueurs", playersTeam)}>
                    <UserAddOutlined /> Répartir les joueurs
                </Button>
                {user?.isAdmin() &&
                    <Upload
                        className={"mt-4 inline-flex items-center"}
                        customRequest={(o) => uploadSessionImage(o, session.id)}
                        name="logo"
                        listType="picture"
                        accept="image/*"
                        showUploadList={false}
                    >
                        <Button className="w-[16.9rem]" icon={<UploadOutlined/>}>Photo de session</Button>
                </Upload>}
            </Flex>
        </Card>
    )
}

function ModalExternLinks({open, setOpen, session, teams}: {open: boolean, setOpen: (value: boolean) => void, session: Session, teams: Team[] | undefined}) {
    const externalVisio = async () => {
        const {data, error} = await supabase.from("session").update({no_jitsi: session?.noJitsi, help_url: session?.helpUrl}).eq("id", session?.id);

        if (teams) {
            for (const team of teams) {
                const {data, error} = await supabase.from("team").update({visio_link: team.visioLink}).eq("id", team.id);
                if (error) {
                    Notification.error("Erreur", error);
                    return;
                }
            }
        }

        if (error) {
            Notification.error("Erreur", error);
            return;
        }
        
        setOpen(false);
    }
    return (
        <Modal
            open={open}
            onOk={externalVisio}
            onCancel={() => setOpen(false)}
        >
            <Checkbox className="mt-8 mb-2" defaultChecked={session?.noJitsi} onChange={(e) => session!.noJitsi = e.target.checked}>Visio externe</Checkbox>
            <Input className="mt-8 mb-2" placeholder="Lien de la visio externe" defaultValue={session?.helpUrl} onChange={(e) => session!.helpUrl = e.target.value} />

            <h1>Lien des visios des équipes</h1>
            {teams?.map((team) => (
                <>
                    <span className="font-bold">{team.name}</span>
                    <Input className="mb-4" placeholder="Lien de la visio externe" defaultValue={team.visioLink} onChange={(e) => team.visioLink = e.target.value} />
                </>
            ))}
        </Modal>
    )
}