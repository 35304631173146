import { useEffect, useMemo, useState } from 'react'
import { ModalDescription, ModalParameters } from '../../../../shared/models/Templates'
import TypeService from '../../../../shared/services/TypeService'

function preloadImage (src: string) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function() {
      resolve(img)
    }
    img.onerror = img.onabort = function() {
      reject(src)
    }
    img.src = src
  })
}

export default function useImagePreloader(modalParameters: ModalParameters[]) {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false)
  
  useEffect(() => {
    const imageList = Array.from(new Set(modalParameters.map(modal => getImageList(modal.descriptions)).flat()));
    let isCancelled = false

    async function effect() {
      console.log('PRELOAD')

      if (isCancelled) {
        return
      }

      const imagesPromiseList: Promise<any>[] = []
      for (const i of imageList) {
        imagesPromiseList.push(preloadImage(i))
      }
  
      await Promise.all(imagesPromiseList)

      if (isCancelled) {
        return
      }

      setImagesPreloaded(true)
    }

    effect()

    return () => {
      isCancelled = true
    }
  }, [modalParameters])

  return { imagesPreloaded }
}

function getImageList(modalDescriptions: ModalDescription | ModalDescription[] | {[key: string]: ModalDescription} | undefined): string[] {
    if (!modalDescriptions) {
        return [];
    }
    const type = TypeService.modalDescriptionType(modalDescriptions);
    const descriptionArray = type === "single" ? [modalDescriptions] : Object.values(modalDescriptions);

    return descriptionArray.filter(description => description.type === "image" && description.type === "background_image").map(description => description.content) || [];
}
