import {UserData} from "../../../shared/models/UserData/UserData";
import Session from "../../../shared/models/Session/session";
import {Company} from "../../../shared/models/Company/company";
import {createContext, useContext} from "react";
import {Team} from "../../../shared/models/Team/Team";
import {Player} from "../../../shared/models/Player/Player";
import { Step } from "../../../scenarios/scenarios";

interface Type {
    team: Team | undefined;
    player: Player | undefined;
    playersTeam: Player[];
    session: Session | undefined;
    user: UserData | undefined;
    company: Company | undefined;
    step: Step | undefined;
    showHint: boolean;
    loading: boolean;
    solution: Solution | undefined;
    containerRef: React.RefObject<HTMLDivElement>;
}

type Solution = {
    triggerSolution: () => void;
    solutionOn: boolean;
}

export const GameContext = createContext<Type>({
    user: undefined,
    session: undefined,
    player: undefined,
    playersTeam: [],
    team: undefined,
    showHint: false,
    loading: true,
    company: undefined,
    step: undefined,
    solution: undefined,
    containerRef: {} as React.RefObject<HTMLDivElement>
});

export const useGameContext = () => useContext(GameContext);