import { Button, Flex, FormInstance } from "antd";
import { useGameContext } from "../../../../pages/session_game/context/context.game";
import SyncronizedSelect from "../../../../shared/components/input/SyncronizedSelect";
import ShuffleService from "../../../../shared/services/ShuffleService";
import { useEffect, useState } from "react";
import ImageRatioBackground from "../../../../shared/components/image/ImageRatioBackground";
import t from "../../../../configs/i18n";

export default function Stage9password(
    props: {
        form: FormInstance,
        onChange: (value: string, id: string) => void
    }
) {
    const context = useGameContext();
    const possibleAnswers = ShuffleService.shuffleArray<string>(context.step?.passwordModal?.inputs.map(input => input.answer) as string[]);
    const button = {
        position: {x: 20.55, y: 21.5},
        size: {w: 72.1, h: 5.2},
        action: () => {
            if (context.step && context.step.passwordModal) {
                context.step.passwordModal.content = undefined;
            }
            props.onChange("", "");
        }
    }
    possibleAnswers.unshift("");

    return (
        <ImageRatioBackground bgImage={`https://api.thewokies.fr/storage/v1/object/public/scenario/dgse/v1/${t.lang}/step9/email.webp`} modal={true} >
            <Flex className={`absolute cursor-pointer bg-transparent`} style={{left: `${button.position.x}%`, top: `${button.position.y}%`, width: `${button.size?.w}%`, height: `${button.size?.h}%`}} onClick={button.action}>
                <div/>
            </Flex>
        </ImageRatioBackground>
    );
}