import { Card, CardProps } from "antd";
import FitText from "../../../shared/components/text/FitText";

export default function TextCard(
    props: {
        text: string;
        options?: {[key: string]: string};
        className?: string;
        fill?: boolean;
        style?: React.CSSProperties;
    } & CardProps
) {
    const color = props.options?.color;
    const textColor = props.options?.textColor;
    const width = props.fill ? '100%' : `${props.options?.cardWidth}%`;
    const height = props.fill ? '100%' : `${props.options?.cardHeight}%`;
    const size = props.options?.textSize;

    const className = ["font-extrabold flex justify-center items-center", props.className, (color && `bg-[${color}]`) ?? "", textColor && `text-[${textColor}]`].join(" ");
    const style = {width: width, height: height};
    return <Card {...props} style={{...style, ...props.style}} className={className} bodyStyle={{padding: 0, width: "100%", height: "100%"}}>
        <div className="text-center w-full h-full px-[7%] py-[5%] flex justify-center items-center">
            <FitText className="w-full h-full flex justify-center items-center" onlyHeight proportion={0.95}>{props.text}</FitText>
        </div>
    </Card>
}