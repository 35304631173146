import type { PositionType, SimpleInput } from "../../models/Templates";
import Normalize from "../formatter/normalize";

export default class StyleService {
    public static getTranslate(position: PositionType | undefined) {
        switch (position) {
            case "center": return "translate(-50%, -50%)";
            case "topLeft": return "translate(0%, 0%)";
            case "topRight": return "translate(-100%, 0%)";
            case "bottomLeft": return "translate(0%, -100%)";
            case "bottomRight": return "translate(-100%, -100%)";
            case "centerLeft": return "translate(0%, -50%)";
            case "centerRight": return "translate(-100%, -50%)";
            case "topCenter": return "translate(-50%, 0%)";
            case "bottomCenter": return "translate(-50%, -100%)";
            default: return "";
        }
    }
    
    public static getInputColorClassName(input: SimpleInput, value: string) {
        const correctBackground = input.color ? Normalize.text(`bg-[${input.color}] disabled:bg-[${input.color}] focus:bg-[${input.color}] hover:bg-[${input.color}] disabled:text-[${input.correctTextColor}] text-[${input.correctTextColor}]`) : "bg-green-500 disabled:bg-green-500 disabled:text-black";
        const background = input.auto_check && value === input.answer ? correctBackground : "disabled:bg-gray-300";
        const color = input.color ? `border-[${input.color}]` : "";
        return `${background} ${color}`;
    }
}