import { Flex } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { useGameContext } from "../../../../pages/session_game/context/context.game";
import Normalize from "../../../../shared/services/formatter/normalize";

export type ClickableObjectParameters = {
    type: "image" | "text" | "invisible" | "debug",
    content?: string,
    size?: {w: number, h: number},
    position: {x: number, y: number},
    action: {
        type: "modal" | "password_modal",
        target?: string,
    },
    players?: number[],
    dummy?: boolean | undefined
};

interface ClickableObjectProps {
    button: ClickableObjectParameters;
    setOpenPasswordModal: (open: boolean) => void;
    openModal: (label: string) => void;
    timeWhenVisible: number | undefined;
};

export default function ClickableObject({button, setOpenPasswordModal, openModal, timeWhenVisible}: ClickableObjectProps) {
    const context = useGameContext();
    const [borderStyle, setBorderStyle] = useState<string>("none");
    const action = () => {
        switch(button.action.type) {
            case "password_modal":
                setOpenPasswordModal(true);
                return;
            case "modal":
                if (button.action.target) {
                    openModal(button.action.target);
                }
                return;
        }
    }

    const isDummy = useMemo(() => {
        if (button.dummy) return true;
        if (button.action.type === "password_modal") return false;
        const modal = Object.entries(context.step?.modals ?? {})
            .find(([name, modal]) => Normalize.modalKey(name) === button.action.target && !modal.dummy && (!modal.players || context.player?.numberAmoung(modal.players)));
        return modal === undefined;
    }, [button.dummy, button.action.target, context.step?.modals, context.player]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isDummy && timeWhenVisible && timeWhenVisible <= new Date().getTime()) {
                setBorderStyle("4px solid lightgreen");
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timeWhenVisible, isDummy]);

    const content = (() => { switch(button.type) {
        case "image":
            return <img onClick={action} src={button.content || ""} width={"100%"} height={"100%"} />;
        case "text":
            return <p>{button.content}</p>;
        case "invisible":
            return <div/>;
        case "debug":
            return <div style={{backgroundColor: "red", width: "100%", height: "100%", opacity: 0.5}}/>;
    }})();


    return <Flex className={`absolute cursor-pointer bg-transparent`} style={{border: borderStyle, left: `${button.position.x}%`, top: `${button.position.y}%`, width: `${button.size?.w}%`, height: `${button.size?.h}%`}} onClick={action}>
        {content}
    </Flex>;
}