import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import AppSpinner from "../../shared/components/spinner/AppSpinner";
import {Button, Checkbox, Flex, Form, Input} from "antd";
import SessionInfoCard from "../../shared/components/utils/SessionInfoCard";
import {UserOutlined} from "@ant-design/icons";
import {Player} from "../../shared/models/Player/Player";
import {supabase} from "../../configs/supabase";
import Notification from "../../shared/services/notification/Notification";
import t from "../../configs/i18n";


/*
    app.thewokies.fr/session_join/<PlayerID>
 */
function SessionJoin() {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [player, setPlayer] = useState<Player | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [check, setCheck] = useState<boolean>(false);
    const navigate = useNavigate();


    const fetchData = async () => {
        const playerQuery = await supabase
            .from("player")
            .select("*, session(*, company(*))")
            .eq("id", id);

        if (playerQuery.error) {
            Notification.error(t.error.player, playerQuery.error);
            navigate("/");
            return;
        }

        const player = await Player.newPlayer(playerQuery.data?.at(0));
        await player?.fetchAdaptation();
        setPlayer(player);

        setLoading(false)

        if (player && player.getName()) {
            if (player?.getSession()?.canJoinExclusively()) {
                navigate(`/session_game/${player?.playerId}`);
                return;
            }  
            navigate(`/session_common_room/${player?.playerId}`);
            return;
        }
    }

    useEffect(() => {
        fetchData();
    }, [id]);

    const goToSession = async () => {
        const updateResponse = await supabase
            .from("userdata")
            .update({
                firstname: form.getFieldValue("firstname"),
                lastname: form.getFieldValue("lastname"),
            })
            .eq("uid", player?.getId())

        if (updateResponse.error) {
            Notification.error(t.error.session, updateResponse.error);
            return;
        }

        if (player?.getSession()?.teamAutoDistribution) {
            const response = await supabase.rpc("auto_team", {
                player_id: player?.playerId,
                session_id: player?.getSession()?.id,
            })

            if (response.error) {
                Notification.error(t.error.session, response.error);
                return;
            }
        }

        navigate(`/session_common_room/${player?.playerId}`);
    }

    if (loading) {
        return <AppSpinner />
    }

    return (
        <Flex className="w-full h-full" vertical>
            <SessionInfoCard company={player?.getSession()?.company} session={player?.getSession()} />
            <Flex align={"center"} justify={"center"} className="w-full mt-10" vertical>
                <h1 className={"mb-10 text-green-dark"}>{t.sessionJoin.playerInfo}</h1>
                <Form form={form} onFinish={goToSession}>
                    <Form.Item name="firstname" rules={[{ required: true, message: "" }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t.common.firstName} />
                    </Form.Item>
                    <Form.Item name="lastname" rules={[{ required: true, message: "" }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t.common.lastName} />
                    </Form.Item>
                    <Form.Item name="check">
                        <Checkbox onChange={(e) => setCheck(e.target.checked)}>{t.sessionJoin.imPresent}</Checkbox>
                    </Form.Item>
                    <Button disabled={!check} className="w-full" type="primary" htmlType="submit">
                        {t.sessionLogin.joinSession}
                    </Button>
                </Form>
            </Flex>
        </Flex>
    );
}

export default SessionJoin;