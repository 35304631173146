interface TextStyle {
  bold?: boolean;
  color?: string;
}

export default function CustomText(
    props: {
        content: string;
        className?: string;
        style?: React.CSSProperties;
    }
) {
  const parseStyleTag = (text: string): React.ReactNode[] => {
    const styleRegex = /{style:([^}]+)}(.*?){\/style}/g;
    let lastIndex = 0;
    const result: React.ReactNode[] = [];
    let match;

    while ((match = styleRegex.exec(text)) !== null) {
      // Add unstyled text before the match
      if (match.index > lastIndex) {
        result.push(text.slice(lastIndex, match.index));
      }

      const styleStr = match[1];
      const content = match[2];
      
      // Parse style attributes
      const styles = styleStr.split(',').reduce((acc, style) => {
        const [key, value] = style.split(':');
        switch (key.trim()) {
          case 'bold':
            acc.bold = true;
            break;
          case 'color':
            acc.color = value.trim();
            break;
        }
        return acc;
      }, {} as TextStyle);

      // Add styled text
      result.push(
        <span
          key={match.index}
          style={{
            fontWeight: styles.bold ? 'bold' : 'normal',
            color: styles.color,
          }}
        >
          {content}
        </span>
      );

      lastIndex = match.index + match[0].length;
    }

    // Add any remaining unstyled text
    if (lastIndex < text.length) {
      result.push(text.slice(lastIndex));
    }

    return result;
  }

  const render = (text: string) => {
    return (
      <span
        style={props.style}
        className={props.className}
      >
        {parseStyleTag(text)}
      </span>
    );
  }

  return render(props.content);
}
