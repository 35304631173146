import { useEffect, useRef, useState, useLayoutEffect } from "react";
import TextService from "../../services/templates/TextService";
export default function FitText({children, className, onlyHeight, onlyWidth, proportion}: {children: React.ReactNode, className?: string, onlyHeight?: boolean, onlyWidth?: boolean, proportion?: number}) {
    const ref = useRef<HTMLDivElement>(null);
    const [content, setContent] = useState<React.ReactNode>(children);
    const handleResize = () => {
        // Wait for next frame to ensure DOM is painted
        requestAnimationFrame(() => {
            TextService.fitText(ref.current, onlyHeight, onlyWidth, proportion);
            setContent(children);
        });
    };

    useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize);
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        // Cleanup
        return () => {
            resizeObserver.disconnect();
        };
    }, [className, children, ref.current, onlyHeight, onlyWidth]);

    return <div ref={ref} className={className}>{content}</div>;
}