import {supabase, supabaseConfig} from "../../../configs/supabase";
import {User} from "@supabase/supabase-js";
import {createClient} from "@supabase/supabase-js";
import {ErrorCode} from "../../services/const/ErrorCode";
import Notification from "../../services/notification/Notification";
import t from "../../../configs/i18n";
import { Role, AccessDictionnary } from "./Role";
import { Gamemaster } from "../Session/gamemaster";
import Normalize from "../../services/formatter/normalize";

export class UserData {
    name: string;
    mail: string | undefined
    uid: string;
    access: AccessDictionnary;
    jitsijwt: string;

    public constructor(obj: any) {
        this.name = Normalize.firstName(obj.firstname) + " " + Normalize.lastName(obj.lastname);
        this.mail = obj.mail;
        this.uid = obj.uid;
        this.jitsijwt = obj.jitsijwt;
        this.access = new AccessDictionnary();
    }

    public static async newUserData(obj: any, updateAccess: boolean = false) : Promise<UserData | undefined> {
        if (!obj) {
            return undefined;
        }
        const user: UserData = new UserData(obj);
        updateAccess && await user.updateAccess();
        return user;
    }

    public static async newUserDatas(obj: any[], updateAccess: boolean = false) : Promise<UserData[]> {
        const users = [];
        for (const user of obj) {
            users.push(await UserData.newUserData(user, updateAccess))
        }
        return users.filter((user) => user !== undefined) as UserData[];
    }

    public async updateAccess() : Promise<void> {
        const {data} = await supabase
            .from("role")
            .select("type, session")
            .eq("userdata", this.uid);

        if (data) {
            this.access = new AccessDictionnary();
            data.forEach((row: any) => {
                this.access.addRole(row.session, row.type);
            });
        }
    }

    public hasRights(role: Role, sessionId: string = "") : boolean {
        if (!this.access) {
            return false;
        }
        return this.access.hasRights(sessionId, role);
    }

    public isAdmin() : boolean {
        return this.hasRights(Role.ADMIN);
    }

    public isGamemaster() : boolean {
        /**
         * Check if the user is a gamemaster without on any session for practical purposes only
         */
        if (!this.access) {
            return false;
        }
        return this.access.isGamemaster();
    }

    public static async getCurrentSupabaseUser() : Promise<User | undefined>  {
        const {data} = await supabase.auth.getSession();
        return data.session?.user;
    }

    public static async getCurrentUser(showError = true) : Promise<UserData | undefined> {
        const user = await UserData.getCurrentSupabaseUser();
        if (!user || !user.id) {
            showError && Notification.error(t.error.user, ErrorCode.EMPTY_USER);
            return undefined;
        }
        
        return await UserData.getUser(user.id, true);
    }

    public static async getUser(userId: string, updateAccess: boolean = false) : Promise<UserData | undefined> {
        const query = await supabase
            .from("userdata_with_email")
            .select("*")
            .eq("uid", userId)
            .single();

        if (query.error) {
            Notification.error(t.error.user, query.error);
            return undefined;
        }

        return UserData.newUserData(query.data, updateAccess);
    }

    public static async connectedUserExists() : Promise<boolean> {
        const user = await UserData.getCurrentSupabaseUser();
        if (user === undefined || user.id === undefined) return false;
        return await UserData.getUser(user.id) !== undefined;
    }

    public static async newUser({mail, password, firstName, lastName, isPlayer, company, session, team, playerNumber, indirect} : NewUserInterface) : Promise<{id: string | undefined, existingUser: boolean}> {
        const metadata = {
            firstName: firstName,
            lastName: lastName,
            isPlayer: isPlayer ? isPlayer : false,
            company: company,
            session: session,
            team: team,
            playerNumber: playerNumber,
        }
        const authSupabase = indirect ? createClient(supabaseConfig.url, supabaseConfig.key, {
            auth: {
                persistSession: false,
                storageKey: "special.auth.token.csv",
                autoRefreshToken: false,
                detectSessionInUrl: false
            }
        }).auth : supabase.auth;

        const {data, error} = await authSupabase.signUp({
                email: mail,
                password: password?? mail,
                options: {
                    data: {
                        firstName: firstName,
                        lastName: lastName,
                        isPlayer: isPlayer ? isPlayer : false,
                        company: company,
                        session: session,
                        team: team,
                        playerNumber: playerNumber,
                    },
                }
            });
        
        if (error && error.message === ErrorCode.USER_ALREADY_EXISTS) {
            const {data, error} = await authSupabase.signInWithPassword({
                email: mail,
                password: password?? mail,
            });
            if (error) {
                Notification.error(t.error.user, error);
                return {id: undefined, existingUser: true};
            }

            if (firstName && lastName) {
                const { error } = await supabase
                    .from("userdata")
                    .update({
                        firstname: firstName,
                        lastname: lastName,
                    })
                    .eq("uid", data?.user?.id);

                if (error) {
                    Notification.error(t.error.user, error);
                }
            }

            return {id: data.user.id, existingUser: true};
        }
        if (error) {
            Notification.error(t.error.user, error);
            return {id: undefined, existingUser: false};
        }

        if (!data) {
            Notification.error(t.error.user, ErrorCode.EMPTY_USER);
            return {id: undefined, existingUser: false};
        }

        return {id: data.user?.id, existingUser: false};
    }

    public mapToGamemaster() : Gamemaster {
        return {uid: this.uid, name: this.name};
    }
}

export interface NewUserInterface {
    mail: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    isPlayer?: boolean;
    company?: string;
    session?: string;
    team?: string;
    playerNumber?: number;
    indirect?: boolean;
}