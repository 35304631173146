import {Carousel, Modal} from "antd";
import {useGameContext} from "../../context/context.game";
import { useState } from "react";

interface PlayerHintPopupProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    number: number;
}

export default function PlayerHintPopup({open, setOpen, number}: PlayerHintPopupProps) {
    const context = useGameContext();
    const [width, setWidth] = useState<number | string | undefined>(undefined);

    return (
        <Modal open={open} onCancel={() => setOpen(false)} footer={false} width={width}>
            <Carousel>
                {context.step?.baseHints.map(HintComponent => {
                    return (
                        <HintComponent number={number} setWidth={setWidth}/>
                    )
                })}
            </Carousel>
        </Modal>
    )
}