import Session from "../../../shared/models/Session/session";
import {Button, Flex, Input, Select} from "antd";
import {useMemo, useState} from "react";
import {TeamOutlined} from "@ant-design/icons";
import {supabase} from "../../../configs/supabase";
import Notification from "../../../shared/services/notification/Notification";
import {useNavigate} from "react-router-dom";
import {Player} from "../../../shared/models/Player/Player";
import {ErrorCode} from "../../../shared/services/const/ErrorCode";
import {v4 as generateUUID} from "uuid";
import {normalizeMail} from "../../../shared/services/utils/normalizeMail";
import {Team} from "../../../shared/models/Team/Team";
import ManualPopupInfoSessionHome
    from "../../home/subPages/session/info/components/createPlayers/manual.popup.info.session.home";
import { UserData } from "../../../shared/models/UserData/UserData";
import { Company } from "../../../shared/models/Company/company";
import Normalize from "../../../shared/services/formatter/normalize";
import t from "../../../configs/i18n";

interface Props {
    sessionRealtime: Session | undefined;
}

enum State {
    PLAYERS,
    TEAM_NAME
}

export default function SessionTabletLoginCard(props: Props) {
    const [players, setPlayers] = useState<string[]>([]);
    const [teamName, setTeamName] = useState<string | undefined>();
    const [state, setState] = useState<State>(State.PLAYERS);
    const [createPlayerPopup, setCreatePlayerPopup] = useState<boolean>(false);
    const navigate = useNavigate();

    const options = useMemo(() => props.sessionRealtime?.additionnals?.players.map(player => ({label: Normalize.firstName(player.firstName) + " " + Normalize.lastName(player.lastName), value: player.mail})), [props.sessionRealtime?.additionnals?.players])

    const getPlayerLabel = (email: string) : string => options?.find(option => option.value === email)?.label ?? "";

    const join = async () => {
        if (!props.sessionRealtime) {
            Notification.error("Erreur lors de la récupération de la session", ErrorCode.EMPTY_VALUE)
            return;
        }

        const response = await supabase
            .from("team")
            .select("id, name, other_players")
            .eq('session', props.sessionRealtime.id)

        if (response.error) {
            Notification.error("Erreur lors de la récupération des équipes", response.error)
            return;
        }


        const teamData = response.data?.find(team => {
            const otherPlayers = team.other_players instanceof Array ? team.other_players : JSON.parse(team.other_players);
            return players.every((player: string) => otherPlayers.includes(getPlayerLabel(player))) && otherPlayers.length === players.length;
        });

        const partialTeamExist = response.data?.find(team => {
            const otherPlayers = team.other_players instanceof Array ? team.other_players : JSON.parse(team.other_players);
            return players.some((player: string) => otherPlayers.includes(getPlayerLabel(player)));
        });
        if (partialTeamExist && !teamData) {
            Notification.error("Au moins un des joueurs est déjà dans une autre équipe", ErrorCode.EMPTY_VALUE)
            return;
        } else if (!teamData && !props.sessionRealtime?.canJoinExclusively()) {
            setState(State.TEAM_NAME)
            return;
        } else if (!teamData) {
            Notification.error("La session a déjà commencé, vous ne pouvez pas rejoindre", ErrorCode.EMPTY_VALUE)
            return;
        }

        const team = Team.newTeam(teamData);
        const playerResponse = await supabase
            .from("player")
            .select("id")
            .eq("team", team?.id)

        if (playerResponse.error) {
            Notification.error("Erreur lors de la récupération du joueur", playerResponse.error)
            return;
        }

        const player = await Player.newPlayer(playerResponse.data?.at(0));
        await player?.fetchAdaptation();
        if (!player?.playerId) {
            Notification.error("Erreur lors de la récupération du joueur", ErrorCode.EMPTY_VALUE)
            return;
        }

        
        const mail = normalizeMail(`${team?.name}-${props.sessionRealtime?.name}@thewokies.fr`);
        const signInResponse = await supabase.auth?.signInWithPassword({
            email: mail,
            password: mail,
        })

        if (signInResponse.error) {
            Notification.error("Erreur lors de l'inscription", signInResponse.error)
            return;
        }

        navigate(`/session_common_room/${player.playerId}`)
    }

    const signUp = async () => {
        const id = generateUUID();
        const mail = normalizeMail(`${teamName}-${props.sessionRealtime?.name}@thewokies.fr`);

        // Check if team name contains only letters and numbers
        if (teamName && !/^[a-zA-Z0-9àáâãäçèéêëìíîïñòóôõöùúûüýÿ ]+$/.test(teamName)) {
            Notification.error(t.error.onlyLettersAndNumbers, ErrorCode.EMPTY_VALUE);
            return;
        }

        // Check if team name already exists
        const existingTeam = await supabase
            .from('team')
            .select('name')
            .eq('session', props.sessionRealtime?.id)
            .eq('name', teamName)
            .maybeSingle();

        if (existingTeam.data) {
            Notification.error("Une équipe avec ce nom existe déjà dans cette session", ErrorCode.EMPTY_VALUE);
            return;
        }

        if (!props.sessionRealtime?.id) {
            Notification.error("Erreur lors de la récupération de la session", ErrorCode.EMPTY_VALUE)
            return;
        }

        const teamReponse = await supabase
            .from('team')
            .insert({
                id: id,
                name: teamName,
                session: props.sessionRealtime.id,
                other_players: JSON.stringify(players.map(getPlayerLabel))
            });
        
        if (teamReponse.error) {
            Notification.error("Erreur lors de la création de l'équipe", teamReponse.error)
            return;
        }
        
        const userData = await UserData.newUser({
            mail: mail,
            firstName: teamName,
            session: props.sessionRealtime.id,
            company: props.sessionRealtime.company.id ?? props.sessionRealtime.company,
            team: id
        });

        if (userData.id === undefined) {
            Notification.error("Erreur lors de la création de l'utilisateur", ErrorCode.EMPTY_VALUE)
            return;
        }

        let player = await Player.doesExist(userData.id, props.sessionRealtime?.id);
        await signIn(mail);
        if (!player.exists && userData.id) {
            player = (await Player.create(userData.id, props.sessionRealtime?.id, id)) ?? player;
        } else if (!player.exists) {
            Notification.error("La session a déjà commencé, vous ne pouvez pas rejoindre", ErrorCode.EMPTY_VALUE)
            return;
        }

        navigate(`/session_common_room/${player?.id}`)
    }

    const signIn = async (mail: string) => {
        const signInResponse = await supabase.auth.signInWithPassword({
            email: mail,
            password: mail
        })

        if (signInResponse.error) {
            Notification.error(t.error.common, signInResponse.error);
            return;
        }
    }


    return (
        <Flex align={"center"} vertical>
            <ManualPopupInfoSessionHome open={createPlayerPopup} setOpen={setCreatePlayerPopup} session={props.sessionRealtime}/>
            <h3>Informations équipes</h3>
            {state === State.PLAYERS && <>
                <Select placeholder={"Joueurs de l'équipe"} onChange={(value) => setPlayers(value)} size={"large"} className={"min-w-[400px] max-w-[600px] min-h-[60px]"} options={options} mode={"multiple"}/>
                <Button onClick={() => setCreatePlayerPopup(true)} className={"mt-6"} size={"large"}>Joueur manquant ?</Button>
                <Button onClick={join} disabled={players.length === 0} className={"mt-6"} type={"primary"} size={"large"}>Rejoindre</Button>
            </>}
            {state === State.TEAM_NAME && <>
                <Input onChange={e => setTeamName(e.target.value)} className={"mt-8"} prefix={<TeamOutlined/>} size={"large"} placeholder={"Nom de l'équipe"}/>
                <Button onClick={signUp} disabled={players.length === 0 || !teamName} className={"mt-12"} type={"primary"} size={"large"}>Rejoindre</Button>
            </>}
        </Flex>
    )
}