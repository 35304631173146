import {Button, Flex, Modal, notification} from "antd";
import {BgColorsOutlined, BulbOutlined, ToolOutlined, WarningOutlined} from "@ant-design/icons";
import {supabase} from "../../../configs/supabase";
import {Team} from "../../../shared/models/Team/Team";
import MissingPlayersHintPopup from "./MissingPlayersHintPopup/MissingPlayersHintPopup";
import {useEffect, useMemo, useState} from "react";
import {Player} from "../../../shared/models/Player/Player";
import Notification from "../../../shared/services/notification/Notification";
import Session from "../../../shared/models/Session/session";
import Const from "../../../shared/services/const/Const";
import t from "../../../configs/i18n";
import {useGameContext} from "../context/context.game";
import CallGamemaster from "../../../shared/components/button/CallGamemaster";
import { AdaptationType } from "../../../shared/models/Player/PlayerAdaptation";

interface SOSPopupProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    team: Team | undefined;
    playersTeam: Player[];
    player: Player | undefined;
    session: Session | undefined;
}

export default function SOSPopup({open, setOpen, playersTeam, team, player, session}: SOSPopupProps) {
    const [missingPlayersPopup, setMissingPlayersPopup] = useState(false);
    const context = useGameContext();
    const hasHint = useMemo(
        () => context.step?.baseHints.length !== 0 && [1, 2, 3, 4].filter(number => !playersTeam.find(player => player.number === number)).length > 0,
        [context.step, playersTeam]
    );
    const adaptations: {[key: string]: boolean} = useMemo(() => ({
        daltonien: player?.adaptation?.includes("daltonien") || false
    }), [player?.adaptation, open]);

    const openHints = () => {
        setMissingPlayersPopup(true);
        setOpen(false)
    }

    const setAdaptation = async (type: AdaptationType) => {
        const {data, error} = await supabase.from("player_adaptation").insert({player_id: player?.playerId, adaptation: type});

        if (error) {
            Notification.error(t.sessionGame.sosPopup.error);
        }
        notification.success({message: t.sessionGame.sosPopup.adaptationSuccess});
        setOpen(false);
    }

    return (
        <>
            <MissingPlayersHintPopup team={team} open={missingPlayersPopup} setOpen={setMissingPlayersPopup} playersTeam={playersTeam}/>
            <Modal zIndex={Const.SOS_POPUP_ZINDEX} open={open} onCancel={() => setOpen(false)} footer={null}>
                <Flex align={"center"} justify={"center"} vertical>
                    <WarningOutlined className={"mt-6 mb-4 text-5xl text-red-500"}/>
                    <h3>{t.sessionGame.sosPopup.title}</h3>
                    <p className={"mt-0"}>{t.sessionGame.sosPopup.message}.</p>
                    <Flex gap={"middle"}>
                        {hasHint && team && <Button onClick={openHints} className={"mb-6 mt-4"} type={"primary"}><BulbOutlined/> {t.sessionGame.sosPopup.buttonHint}</Button>}
                        <CallGamemaster setOpen={setOpen}/>
                    </Flex>
                    <Flex>
                        {session?.helpUrl && <Button href={session.helpUrl} target={"_blank"} className={"mb-6"} danger><ToolOutlined /> {t.sessionGame.sosPopup.buttonHelp}</Button>}
                    </Flex>
                    <Flex>
                        {context?.step?.adaptations?.includes("daltonien") && !adaptations.daltonien && <Button onClick={() => setAdaptation("daltonien")} className={"mb-6"} type="primary"><BgColorsOutlined /> {t.sessionGame.sosPopup.adaptationButtons.daltonien}</Button>}
                    </Flex>
                </Flex>
            </Modal>
        </>
    )
}