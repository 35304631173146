import { Button, Card, Flex, Modal } from "antd";
import { useGameContext } from "../../../../pages/session_game/context/context.game";
import { useEffect, useState } from "react";
import { supabase } from "../../../../configs/supabase";
import AppSpinner from "../../../../shared/components/spinner/AppSpinner";
import Notification from "../../../../shared/services/notification/Notification";
import t from "../../../../configs/i18n";
import ImageRatioBackground from "../../../../shared/components/image/ImageRatioBackground";
import FitText from "../../../../shared/components/text/FitText";
import TotalTeamTimer from "../../../../shared/components/time/TotalTeamTimer";
import TimeVisualizer from "../../../../shared/components/time/TimeVisualizer";

export default function Step12() {
    const context = useGameContext();
    const options = context?.step?.room?.templateOptions;
    const [loading, setLoading] = useState<boolean>(true);
    const [open, setOpen] = useState<boolean>(false);
    
    const fetchData = async () => {
        const response = await supabase.rpc("end_game", {
            team_id: context.team?.id
        })

        if (response.error) {
            Notification.error(t.error.common, response.error);
        }

        setLoading(false);
    }
    
    useEffect(() => {
        fetchData();
    }, []);

    if (loading || !options) {
        return <AppSpinner/>
    }

    return (
        <ImageRatioBackground bgImage={options["background_image"]}>
            <InfoModal open={open} setOpen={setOpen}/>
            <Flex className={"absolute top-[4.7%] left-[3.4%] w-[44.3%] h-[44.4%]"}>
                <video controls={true} src={options["video"]} className={"w-full"}/>
            </Flex>
            <Flex className={"absolute top-[6.5%] left-[75%] w-[25%] h-[32%] -translate-x-1/2"} vertical>
                <FitText className="text-center h-[45%] w-[80%] self-center">
                    <strong>{t.common.congratulations} {context.team?.name}</strong>
                </FitText>
                <FitText className="text-center w-[90%] h-[45%] self-center"><span>{options["texte"]}</span></FitText>
                <Button type="primary" className="self-center mt-[3.5%] w-[50%] h-[12%] text-[0.7vw]" onClick={() => setOpen(true)}>{options["button"]}</Button>
            </Flex>
        </ImageRatioBackground>
    );
}

function InfoModal({open, setOpen}: {open: boolean, setOpen: (open: boolean) => void}) {
    const context = useGameContext();

    return <Modal open={open} footer={false} onCancel={() => setOpen(false)} className="w-[500px]">
        <Flex align={"flex-center"} justify={"center"} className="h-[400px] text-center" vertical>
            <Card className="mt-4">
                <h2 className="text-green-dark">{context.team?.name}, {t.sessionGame.youCompleted} :</h2>
                <TotalTeamTimer team={context.team} session={context.session}/>
            </Card>
            <Card>
                <h2 className="text-green-dark">{t.sessionGame.debrief} :</h2>
                <TimeVisualizer showSeconds={false} timestamp={new Date(context?.session?.endDate || 0).getTime()}/>
            </Card>
        </Flex>
    </Modal>
}