import { Flex, FormInstance } from "antd";
import { useGameContext } from "../../../../pages/session_game/context/context.game";
import Code1number from "../../inputs/Code1number";
import { ModalDescription } from "../../../../shared/models/Templates";

export default function PasswordStep1(
    props: {
        form: FormInstance,
        onChange: (value: string, id: string) => void
    }
) {
    const context = useGameContext();
    const description = (context.step?.passwordModal?.descriptions as ModalDescription[])[0];

    return (
        <Flex className={"w-full h-full"} vertical>
            <Flex className="text-[#253570] text-bold text-center justify-center text-[5em] mt-[1%] font-bold">
                {description.type === "image" && <img src={description.content} className="mt-[5%] w-[65%]" />}
                {description.type === "text" && description.content}
            </Flex>
            {context.step?.passwordModal && <Flex className="w-full h-full justify-center items-center mt-[5%]">
                <Code1number inputs={context.step?.passwordModal?.inputs}
                    form={props.form}
                    onChange={props.onChange}
                />
            </Flex>}
        </Flex>
    );
}