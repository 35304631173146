export default class TextService {
    public static fitText(outputSelector: HTMLDivElement | null, onlyHeight: boolean = false, onlyWidth: boolean = false, proportion: number = 1) {
        const maxFontSize = 200;
        const minFontSize = 0; // Define a minimum font size to prevent it from becoming too small

        if (!outputSelector) {
            return;
        }

        let width = outputSelector.clientWidth;
        let height = outputSelector.clientHeight;
        let contentWidth = outputSelector.scrollWidth;
        let contentHeight = outputSelector.scrollHeight;
        let fontSize = parseInt(window.getComputedStyle(outputSelector, null).getPropertyValue('font-size'), 10);

        // Adjust font size until content fits within the element
        while (((!onlyHeight && contentWidth > width) || (!onlyWidth && contentHeight > height)) && fontSize > minFontSize) {
            fontSize -= 1;
            outputSelector.style.fontSize = fontSize + 'px';
            contentWidth = outputSelector.scrollWidth;
            contentHeight = outputSelector.scrollHeight;
        }

        while (((onlyHeight || contentWidth <= width) && (onlyWidth || contentHeight <= height)) && fontSize < maxFontSize) {
            fontSize += 1;
            outputSelector.style.fontSize = fontSize + 'px';
            contentWidth = outputSelector.scrollWidth;
            contentHeight = outputSelector.scrollHeight;
            if ((!onlyHeight && contentWidth > width) || (!onlyWidth && contentHeight > height)) {
                fontSize -= 1;
                outputSelector.style.fontSize = fontSize + 'px';
                break;
            }
        }

        fontSize = Math.round(fontSize * proportion);
        outputSelector.style.fontSize = fontSize + 'px';
        

        sessionStorage.setItem('fontSize', fontSize.toString());
        sessionStorage.setItem('textInput', outputSelector.innerHTML);
    }
}