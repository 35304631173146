import { Button, Flex } from "antd";
import BasicStepTemplate from "./BasicStepTemplate";
import { useGameContext } from "../../../pages/session_game/context/context.game";
import StrTemp from "../../../shared/services/formatter/stringTemplates";
import { WarningOutlined } from "@ant-design/icons";
import PasswordModal from "../modalTemplates/PasswordModal";
import { useState } from "react";
import t from "../../../configs/i18n";
import FitText from "../../../shared/components/text/FitText";
import PopupGoodAnswer from "../../../shared/components/popup/PopupGoodAnswer";

export default function VideoWithInformation() {
    const context = useGameContext();
    const options = context?.step?.room?.templateOptions;
    const [openPass, setOpenPass] = useState<boolean>(false);
    const [openPassModal, setOpenPassModal] = useState<boolean>(false);
    if (!options || !context.player?.number) return null;
    const openPasswordModal = () => {
        if (context.step?.passwordModal === undefined) {
            setOpenPass(true);
            return;
        }
        
        setOpenPassModal(true);
    }

    return (
        <BasicStepTemplate
            openPasswordModal={openPassModal}
            setOpenPasswordModal={setOpenPassModal}
        >
            {context.step?.passwordModal === undefined ? <PopupGoodAnswer
                    open={openPass}
                    setOpen={setOpenPass}
                    team={context.team}
                    player={context.player}
                    playersTeam={context.playersTeam}
                    currentUser={context.user}
                    customText={t.scenario.dgse.nextStep}
                    closable={true}
                    yesButton={t.scenario.dgse.videoYes}
                    noButton={t.scenario.dgse.videoNo}
                    noConfetti={true}
                />
                : <></>}
            <Flex className={"w-full jutify-items-center mx-[1vw] space-x-[1vw] font-bold"}>
                <video controls={true} src={options["video"]} className={"w-3/4 max-h-[100%] self-center"}/>
                <Flex className={"w-1/4 h-full items-center justify-center"} vertical>
                    <div className="text-bold w-full h-[12%] flex place-items-center">
                        <FitText className="w-[25%] h-full flex place-items-center">
                            <WarningOutlined className={"text-red-500 mr-2"}/>
                        </FitText>
                        <FitText className="w-full h-full flex place-items-center">
                            <span id="something">{t.scenario.dgse.writeReminder}</span>
                        </FitText>
                    </div>
                    <img src={StrTemp.playerNumber(options["hint"], context.player.number)} className="w-full mb-[5%]" />
                    <Button type="primary" className="whitespace-normal justify-center w-full h-[10%] text-[1.1vw] flex place-items-center" onClick={openPasswordModal}>
                        <FitText className="w-[90%] h-[90%] flex place-items-center">{options["button"]}</FitText>
                    </Button>
                </Flex>
            </Flex>
        </BasicStepTemplate>
    );
}