import {Col, Flex, Image, Row} from "antd";
import React from "react";
import Session from "../../../shared/models/Session/session";
import {Company} from "../../../shared/models/Company/company";
import {Team} from "../../../shared/models/Team/Team";
import Timer from "../../../shared/components/time/Timer";
import SOSButton from "./SOSButton";
import {Player} from "../../../shared/models/Player/Player";
import GmButton from "./GmButton";
import {UserData} from "../../../shared/models/UserData/UserData";
import Const from "../../../shared/services/const/Const";
import t from "../../../configs/i18n";
import HintButton from "./SoloComponent/HintButton";
import { useGameContext } from "../context/context.game";

interface HeaderInGameProps {
    session: Session | undefined
    company: Company | undefined | null
    team: Team | undefined
    currentPlayer: Player | undefined
    currentUser: UserData | undefined
    playersTeam: Player[]
    solo: boolean
}


export default function HeaderInGame({session, company, playersTeam, team, currentPlayer, currentUser, solo} : HeaderInGameProps) {
    const context = useGameContext()

    return (
        <div style={{zIndex: Const.HEADER_IN_GAME}} className="w-full h-12 bg-beige mb-[3px]">
            <Row className="h-full">

                <Col span={6}>
                    <Flex align={"center"} className={"h-full"}>
                        <Image src={company?.logo} className="ml-4 mr-6 max-h-[40px] max-w-[60px] object-scale-down" placeholder={false} preview={false}/>
                        <div className="text-xs text-green-light">
                            <p className="font-bold m-0">{session?.name}</p>
                            <p className="m-0">{company?.name}</p>
                        </div>
                    </Flex>
                </Col>

                <Col span={12}>
                    <Row className={"h-full text-center"}>
                        <Col span={6}>
                            {team && <p className={"text-right"}><strong>{team?.name}</strong></p>}
                        </Col>
                        <Col span={12}>
                            {team && !team.endTime && <Timer team={team} initialDate={new Date(session?.startGameDate || new Date())}/>}
                        </Col>
                        <Col span={6}>
                            {team && <p className={"text-left"}><strong>{t.common.step} {team?.step}</strong></p>}
                        </Col>
                    </Row>
                </Col>

                <Col span={6}>
                    <Flex gap={"5px"} className={"mr-4 h-full"} align={"center"} justify={"flex-end"}>
                        <GmButton currentPlayer={currentPlayer} team={team} playersTeam={playersTeam}/>
                        {context.step?.description?.hints && context.step?.description?.solution && !context.solution?.solutionOn && <HintButton session={session} />}
                        <SOSButton session={session} player={currentPlayer} team={team} playersTeam={playersTeam}/>
                    </Flex>
                </Col>
            </Row>
        </div>
    )
}