import { supabase } from "../../../configs/supabase";
import { Player } from "./Player";

export type AdaptationType = "daltonien"

export default class PlayerAdaptation {
    public static async byPlayerId(playerId: string) : Promise<AdaptationType[] | undefined> {
        const {data, error} = await supabase.from("player_adaptation").select("adaptation").eq("player_id", playerId);
        return data?.map((row: any) => row.adaptation);
    }

    public static async subscribeToAdaptations(playerId: string, setPlayer: (player: Player) => void, columns: string) {
        return supabase.channel(`player-adaptations-${playerId}`).on("postgres_changes", {
            schema: 'public',
            table: "player_adaptation",
            event: '*',
        }, (payload: any) => {
            Player.byId(playerId, columns, false).then((player) => {
                if (player) {
                    setPlayer(player);
                }
            });
        }).subscribe();
    }

    public static async getAllAdaptations() {
        const {data, error} = await supabase.from("player_adaptation").select("*");
        return data?.map((row: any) => {
            return {
                id: row.id,
                player_id: row.player_id,
                adaptation: row.adaptation,
                created_at: row.created_at,
            };
        });
    }
}
