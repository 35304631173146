import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined, CaretRightOutlined } from '@ant-design/icons';

export default function MinimalAudioButton(
    props: {
        audioPath: string;
        className?: string;
        size?: number;
        callbackPlay?: (element: HTMLAudioElement) => void;
        style?: React.CSSProperties;
    }
) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState<HTMLAudioElement>();

  useEffect(() => {
    const audio = new Audio(props.audioPath);
    audio.addEventListener("pause", () => setIsPlaying(false));
    // To reset the state when the audio ends
    audio.onended = () => {
      setIsPlaying(false);
    };
    setAudio(audio);

    return () => {
      audio.pause();
      audio.removeEventListener("pause", () => setIsPlaying(false));
      // Delete the audio element
      audio.remove();
    }
  }, [props.audioPath]);

  const handlePlayPause = () => {
    if (!audio) return;
    if (isPlaying) {
      audio.pause();
    } else {
      props.callbackPlay?.(audio);
      audio.play();
      setIsPlaying(true);
    }
  };


  return (
    <div
      className={`flex items-center justify-center border border-solid border-2 border-gray-500 bg-white hover:bg-gray-300 ${props.className} rounded-full`}
      style={{...props.style, width: `${props.size ? props.size * 1.3 : 1.3}em`, height: `${props.size ? props.size * 1.3 : 1.3}em`}}
      onClick={handlePlayPause}
    >
        {isPlaying ? <PauseCircleOutlined style={{ fontSize: `${props.size}em`, color: '#555555' }} /> 
                      : <CaretRightOutlined style={{ fontSize: `${props.size}em`, color: '#555555' }} />}
    </div>
  );
};
