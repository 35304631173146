import {ReactElement, ReactNode, useEffect, useState} from "react";
import {Team} from "../../../../shared/models/Team/Team";
import {Player} from "../../../../shared/models/Player/Player";
import {TabletQuizzContext} from "./context.tablet.quizz";
import Session from "../../../../shared/models/Session/session";
import Question from "../../step_quizz/type/Question";
import {BaseHintProps} from "../../../types/BaseHintProps";
import { Step } from "../../../scenarios";

interface Props {
    children: ReactNode;
    session: Session | undefined;
    step: Step | undefined;
}

export default function ProviderTabletQuizz(props: Props) {
    const [loading, setLoading] = useState<boolean>(true);
    const [teams, setTeams] = useState<Team[]>([]);
    const [players, setPlayers] = useState<Player[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            setTeams(await Team.bySession(props.session, "*"))
            setPlayers(await Player.bySession(props.session));
        }
        fetchData();

        const playersChannel = Player.listenBySession(props.session, setPlayers);
        const teamChannel = Team.listenInSession(props.session, setTeams)

        return () => {
            playersChannel?.unsubscribe();
            teamChannel?.unsubscribe();
        }

    }, [props.session]);

    const questions : Question[] = props.step?.description.questions;

    return (
        <TabletQuizzContext.Provider value={{loading, teams, players, questions}}>
            {props.children}
        </TabletQuizzContext.Provider>
    )
}