import LocaleText from "./localeText";

export default class FR extends LocaleText {
    lang = "fr";
    login = {
        title: "Bienvenue sur votre espace !",
        cardTitle: "Accéder à The Wokies",
        success: "Un e-mail de connexion a été envoyé à votre adresse e-mail.",
        imaplayer: "Je suis un joueur",
    }

    home = {
        dashboard: "Tableau de bord",
        session: {
            create: "Créer une session",
            result: "Résultats de la session",
            showResult: "Découvrir les résultats",
            createSuccess: "Session créée avec succès",
            seePhoto: "Découvrez les photos",
            exportSurvey: "Exporter les résultats",
            exportPlayerStatus: "Exporter la feuille présence",
            deleteAlert: "Voulez-vous vraiment supprimer cette session ?",
            passed: "Sessions passées",
            future: "Sessions à venir",
            inProgress: "Sessions en cours",
            stats: "Statistiques",
            podium: "Podium",
            noPhoto: "Aucune photos",
        },
        company: {
            create: "Créer une entreprise",
            name: "Nom de l'entreprise",
            logo: "Logo de l'entreprise",
        }
    }

    common = {
        answer: "Réponse",
        congratulations: "Félicitations",
        connect: "Se connecter",
        mail: "Email",
        password: "Mot de passe",
        company: "Entreprise",
        companies: "Entreprises",
        name: "Nom",
        firstName: "Prénom",
        lastName: "Nom",
        date: "Date",
        update: "Mettre à jour",
        yourTeam: "Votre équipe",
        noTeam: "Aucune équipe",
        team: "Équipe",
        step: "Étape",
        player: "Joueur",
        goBack: "Retour",
        validate: "Valider",
        cancel: "Annuler",
        warning: "Attention !",
        finished: "Terminé",
        continue: "Continuer",
        only: "Seulement",
        secondes: "secondes",
        yourAnswer: "Votre réponse",
        optional: "Optionnel",
        yes: "Oui",
        no: "Non",
        wrongAnswer: "Mauvaise réponse",
        goodAnswer: "Bonne réponse",
        noAnswer: "Aucune réponse",
        allFieldsFilled: "Tous les champs doivent être remplis",
        noted: "C'est noté",
    }

    error = {
        common: "Une erreur est survenue, veuillez réessayer ultérieurement.",
        wrongMail: "Aucun utilisateur n'a été trouvé avec cette adresse e-mail.",
        wrongPassword: "L'adresse e-mail ou le mot de passe est incorrect.",
        wait60: "Veuillez attendre 60 secondes avant de soumettre une nouvelle demande.",
        user: "Erreur lors de la récupération de l'utilisateur.",
        disconnect: "Erreur lors de la déconnexion de l'utilisateur.",
        session: "Une erreur est survenue lors de la récupération de la session.",
        company: "Erreur lors de la récupération des entreprises.",
        score: "Erreur lors de la récupération des scores.",
        players: "Erreur lors de la récupération des joueurs.",
        player: "Erreur lors de la récupération du joueur.",
        team: "Erreur lors de la récupération de l'équipe.",
        teams: "Erreur lors de la récupération des équipes.",
        notConnected: "Cette session a expirée. Veuillez vous reconnecter.",
        linkExpired: "Ce lien a déjà été utilisé. Veuillez demander un nouveau lien de connexion.",
        gamemaster: "Erreur lors de l'appel au gamemaster.",
        chrono: "Erreur lors de la récupération du chronomètre.",
        subscription: "Erreur lors de la souscription.",
        survey: "Erreur lors de la récupération du questionnaire.",
        input: "Erreur lors de la récupération de la saisie.",
        dataMap: "Erreur lors de la conversion des données.",
        validateStep: "Erreur lors de la validation de l'étape.",
        noSlash: "Le nom ne peut pas contenir de '/'",
        onlyLettersAndNumbers: "Le nom ne peut pas contenir de caractères spéciaux",
        emptySolution: "Une erreur s'est produite lors de l'obtention de la solution.",
    };

    test = {
        api: "Échec du chargement des ressources de l'API.",
        websocket: "Impossible de se connecter au WebSocket.",
        jass: "Accès à 8x8.vc échoué.",
        jitsi: "Accès à web-cdn.jitsi.net échoué.",
        supabase: "Accès à la base de données échoué.",
        success: "Les tests ont été réalisés avec succès !",
        error: "Une erreur a été détectée.",
    }

    sessionLogin = {
        alreadyStarted: "La session a déjà commencé. Vous devez vous être connecté au moins une fois pour y accéder de nouveau.",
        cannotJoin: "La session a déjà commencé, et il n'est plus possible de la rejoindre.",
        enterMail: "Renseignez votre mail professionnel",
        enterTeam: "Renseignez le nom de votre équipe",
        joinSession: "Rejoindre la session",
        closedState: "La session n'a pas encore commencé !",
        comeBack: "Revenez le",
        toConnect: "pour vous connecter",
        problemDetected: "Un problème a été détecté",
        accessLimited: "Votre accès est actuellement limité à certaines fonctionnalités, ce qui vous empêchera de participer au jeu.",
        helpUrl: "Accéder au canal technique pour obtenir de l'aide",
        organisatorHasSolved: "Je confirme que l'organisateur a résolu mon problème",
    }

    sessionJoin = {
        imPresent: "Je suis présent durant la durée de la session (environ 1h30)",
        teamInfo: "Informations équipe",
        playerInfo: "Informations joueurs",
        team: "Équipe",
    }

    sessionGame = {
        noStep: "Aucune étape n'a été trouvée.",
        noTeam: "Vous n’êtes actuellement affecté à aucune équipe",
        suggestCalling: "Veuillez contacter un game master",
        canClosePopup: "Vous pouvez fermer ce popup, votre saisie est enregistrée !",
        wrongAnswer: "Réponse incorrecte",
        wrongAnswers: "Une ou plusieurs réponses sont incorrectes",
        crosswordIncorrect: "La grille est incorrecte",
        goToQuizz: "Aller au quizz",
        confirmationGoToQuizzL1: "Êtes-vous sûr de vouloir passer au quizz ?",
        confirmationGoToQuizzL2: "Vous ne pourrez plus revoir la vidéo une fois le quizz commencé.",
        youCompleted: "vous avez réussi toutes les énigmes en",
        waitingForTeam: "En attente des dernières équipes...",
        debrief: "Le debriefing global aura lieu à",
        thisQuestionNotExists: "Cette question n'existe pas.",
        canAnswer: "peut répondre, il faut coopérer!",
        visio: {
            accessVisio: "Accéder à la visio-conférence",
            noVisioLink: "Aucun lien de visio trouvé",
            mainVisio: "Cliquez ici pour accéder à la visioconférence principale",
            teamVisio: "Cliquez ici pour accéder à votre visioconférence d’équipe",
        },
        winBonus: "Répondez correctement pour gagner 30 secondes",
        goodAnswerQuiz: "Bravo, réponse correcte ! Vous gagnez un bonus de",
        wrongAnswerQuiz: "Mauvaise réponse, votre équipe a un malus de",
        youAnswered: "Vous avez répondu",

        gamemasterPopup: {
            title: "En attente du gamemaster",
            calledBy: "Un problème a été signalé par",
            messageChrono: "Le chronomètre est en pause en attendant la résolution de votre problème.",
        },
        sosPopup: {
            title: "Quelle est la nature de votre problème ?",
            message: "Aidez nous à en savoir plus sur votre problème",
            buttonHint: "Indices complémentaires",
            buttonGamemaster: "Appeler un gamemaster",
            buttonHelp: "Canal technique",
            adaptationSuccess: "Changement enregistré avec succès",
            error: "Une erreur est survenue lors de l'application du changement.",
            adaptationButtons: {
                daltonien: "Je suis daltonien",
            }
        },
        hintPopup: {
            title: "Indices complémentaires",
            noPlayer: "Aucun joueur n'est absent.",
        },
        game: {
            inputOtherPlayer: "Saisi par un autre joueur",
            toRemember: "L'information à retenir !",
        },
        cameraPopup: {
            title: "La visio-conférence va démarrer !",
            message: "Pensez à bien activer votre micro et votre caméra sur la visio conférence, en acceptant cela dans votre navigateur",
            message2: "Attention, par défaut vous n'y êtes pas connecté(e) et ne pouvez donc pas suivre les discussions.",
            button: "C'est bien noté !",
        },
        goodAnswerPopup: {
            title: "Vous avez trouvé la bonne réponse !",
            button: "Passer à l'étape suivante",
        },
        timerPausedPopup: {
            title: "Le temps est mis en pause !",
            message: "Durant les vidéos et les explications, le temps est mis en pause. Prenez donc le temps de bien tout retenir !",
            button: "C'est compris !",
        },
        waitingForPlayers: {
            error: "Une erreur est survenue lors de la validation de l'étape.",
            title: "En attente des autres joueurs",
        },
        hints: {
            hints: "Indices",
            yourhints: "Vos indices",
            penalty: "Chaque indice débloqué engendre une pénalité",
            hint: "Indice",
            click: "Cliquez pour consulter cet indice",
            unlock: "Débloquer cet indice",
            cantUnlock: "Vous devez d'abord débloquer les indices précédents",
            order: "Les indices sont débloqués dans l'ordre",
            answer: "Débloquer la solution",
            answerCondition: "Vous pouvez avoir accès à la réponse uniquement après avoir débloqué tous les indices",
            confirmationQuestion: "Êtes-vous certain de vouloir débloquer un indice ?",
            confirmationSolution: "Êtes-vous certain de vouloir débloquer la solution ?",
            confirmationInfo: "Cela entrainera un malus de 30 secondes",
            confirmationInfo1: "Cela entrainera un malus de ",
            confirmationInfo2: "",
            secondes: "secondes",
            and: "et",
            minutes: "minutes",
            solutionComplete: "Solution complétée avec succès!"
        },
        actionType: {
            user: "Action individuelle",
        }
    }

    sessionEnd = {
        seeRanking: "Voir le classement",
        sessionEnded: "La session est terminée !",
        survey: {
            success: "Le questionnaire a été envoyé avec succès.",
            error: "Erreur survenue lors de l'envoi du questionnaire.",
            emptyFields: "Veuillez remplir tous les champs non optionnel.",
            survey: "Questionnaire de satisfaction",
            thanks: "Merci d'avoir répondu au questionnaire de satisfaction",
            globalRating: "J'ai passé un bon moment avec mes collègues ?",
            tryAgain: "Souhaiteriez-vous renouveler l'expérience Escape Game en Ligne avec vos collègues ?",
            game: "Le scénario vous a-t-il plu ?",
            recommendation: "Recommanderiez-vous cette activité à un collègue/une relation professionnelle ?",
            ratingGame: "Avez-vous appris quelque chose ?",
            input: "Selon vous, que pourrions-nous améliorer ? Quels sont vos conseils ?",
            email: "Mail",
        }
    }

    spinner = {
        message: "Le chargement prend trop de temps ?",
        tryAgain: "Réessayer",
    }

    syncPopup = {
        title: "Perte de synchronisation",
        message: "Resynchronisation en cours avec la session...",
    }

    
    fileNames = {
        survey: "TheWokies_resultats_questionnaire_",
        attendance: "TheWokies_feuille_de_presence_",
        template: "TheWokies_FichiersParticipants_",
    }

    bdd = {
        teamName: "Equipe",
    }

    scenario = {
        dgse : {
            unlockingCode: "Déverrouillage",
            writeReminder: "N'oubliez pas de noter cette information",
            nextStep: "Avez-vous regardé la vidéo et récupéré votre post-it ?",
            videoYes: "Oui, passer à l'étape suivante",
            videoNo: "Non, j'ai besoin de plus de temps !",
        }
    }

    adaptation = {
        notButton: "Le joueur n'est pas ",
    }

    admin = {
        newParticipant: {
            email: "Mail",
            firstName: "Prénom",
            lastName: "Nom",
            company: "Entreprise",
        }
    }
}