import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import AppSpinner from "../../shared/components/spinner/AppSpinner";
import {Button, Checkbox, Flex, Form, Input} from "antd";
import SessionInfoCard from "../../shared/components/utils/SessionInfoCard";
import {UserOutlined} from "@ant-design/icons";
import {Player} from "../../shared/models/Player/Player";
import {supabase} from "../../configs/supabase";
import Notification from "../../shared/services/notification/Notification";
import t from "../../configs/i18n";
import scenarios from "../../scenarios/scenarios";


/*
    app.thewokies.fr/session_join/<PlayerID>
 */
function SessionTabletJoin() {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [player, setPlayer] = useState<Player | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [check, setCheck] = useState<boolean>(false);
    const navigate = useNavigate();

    const scenario = useMemo(() => scenarios[player?.getSession()?.scenario as string], [player?.getSession()?.scenario])


    const fetchData = async () => {
        const playerQuery = await supabase
            .from("player")
            .select("*, session(*, company(*))")
            .eq("userdata", id);


        if (playerQuery.error) {
            Notification.error(t.error.player, playerQuery.error);
            navigate("/");
            return;
        }

        const player = await Player.newPlayer(playerQuery.data?.at(0));
        await player?.fetchAdaptation();
        setPlayer(player);
        form.setFieldValue("name", player?.getName().split("@")[0].replaceAll("-", " "));

        setLoading(false)
    }

    useEffect(() => {
        fetchData();
    }, [id]);

    const goToSession = async () => {
        const updateResponse = await supabase
            .rpc("update_user_and_player_names", {
                user_mail: player?.getEmail(),
                new_name: form.getFieldValue("name"),
            })

        if (updateResponse.error) {
            Notification.error(t.error.session, updateResponse.error);
            return;
        }

        if (player?.getSession()?.teamAutoDistribution) {
            const response = await supabase.rpc("auto_team", {
                player_id: player?.playerId,
                session_id: player?.getSession()?.id,
            })

            if (response.error) {
                Notification.error(t.error.session, response.error);
                return;
            }
        }

        if (scenario.tablet) {
            const response = await supabase.from("player").update({
                additionals: JSON.stringify({
                    players : [
                        form.getFieldValue("player1"),
                        form.getFieldValue("player2"),
                        form.getFieldValue("player3"),
                        form.getFieldValue("player4"),
                    ]
                })
            }).eq("id", player?.playerId)

            if (response.error) {
                Notification.error(t.error.session, response.error);
                return;
            }
        }

        navigate(`/session_common_room/${player?.playerId}`);
    }

    if (loading) {
        return <AppSpinner />
    }

    return (
        <Flex className="w-full h-full" vertical>
            <SessionInfoCard company={player?.getSession()?.company} session={player?.getSession()} />
            <Flex align={"center"} justify={"center"} className="w-full mt-10" vertical>
                <h1 className={"mb-10 text-green-dark"}>{t.sessionJoin.teamInfo}</h1>
                <Form form={form} onFinish={goToSession}>
                    <Form.Item name="name" rules={[{ required: true, message: "" }]}>
                        <Input disabled={true} prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t.common.team} />
                    </Form.Item>
                    <Form.Item name="player1" rules={[{ required: true, message: "" }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={`${t.common.player} 1 ${t.common.mail}`} />
                    </Form.Item>
                    <Form.Item name="player2" rules={[{ required: true, message: "" }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={`${t.common.player} 2 ${t.common.mail}`} />
                    </Form.Item>
                    <Form.Item name="player3" rules={[{ required: true, message: "" }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={`${t.common.player} 3 ${t.common.mail}`} />
                    </Form.Item>
                    <Form.Item name="player4" rules={[{ required: true, message: "" }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={`${t.common.player} 4 ${t.common.mail}`} />
                    </Form.Item>
                    <Button  className="w-full" type="primary" htmlType="submit">
                        {t.sessionLogin.joinSession}
                    </Button>
                </Form>
            </Flex>
        </Flex>
    );
}

export default SessionTabletJoin;