import {BrowserRouter as Router, Route, Routes, useParams} from "react-router-dom";
import LoginPage from "./login/login.page";
import HomePage from "./home/home.page";
import SessionGm from "./session_gm/SessionGm";
import SessionCommonRoom from "./session_common_room/SessionCommonRoom";
import SessionLogin from "./session_login/SessionLogin";
import SessionJoin from "./session_join/SessionJoin";
import SessionGame from "./session_game/SessionGame";
import SessionEnd from "./session_end/SessionEnd";
import Test from "./test/Test";
import TestJitsi from "./test_jitsi/TestJitsi";
import UnknownPage from "./404/404";
import MagicLinkPage from "./magic_link/magicLink.page";
import SessionTabletJoin from "./session_tablet_join/SessionTabletJoin";
import SessionTabletLogin from "./session_tablet_login/SessionTabletLogin";
import { useEffect } from "react";

export default function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LoginPage/>}/>
                <Route path="/login" element={<LoginPage passwordLogin/>}/>
                <Route path="/tablet" element={<LoginPage passwordLogin tablet/>}/>
                <Route path="/home/*" element={<HomePage/>}/>
                <Route path="/magic_link" element={<MagicLinkPage/>}/>
                <Route path="/session_gm/:id" element={<SessionGm/>}/>
                <Route path="/session_common_room/:id" element={<SessionCommonRoom/>}/>
                <Route path="/session_login/:id" element={<SessionLogin/>}/>
                <Route path="/session_join/:id" element={<SessionJoin/>}/>
                <Route path="/session_tablet_join/:id" element={<SessionTabletJoin/>}/>
                <Route path="/session_game/:id" element={<SessionGame/>}/>
                <Route path="/session_end/:id" element={<SessionEnd/>}/>
                <Route path="/session_tablet_login/:id" element={<SessionTabletLogin/>}/>
                <Route path="/test" element={<Test/>}/>
                <Route path="/test_jitsi" element={<TestJitsi/>}/>
                <Route path="*" element={<UnknownPage/>}/>
                <Route path="/docs/:name" element={<DocPage/>}/>
            </Routes>
    </Router>
    )
}

function DocPage() {
    const { name } = useParams();
    useEffect(() => {
        window.location.href = `https://api.thewokies.fr/storage/v1/object/public/documents/docs/${name}`;
    }, [name]);
    return null;
}
